import React, { Component } from 'react'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import eventData from '../../data/models/events'
import Querying from './querying'
import Results from './results'
import { getRelativeTimes } from './querying/timesets'
import { formatUTC } from './querying/timerange'
import Header from './header'

const timeFmt = "yyyy-MM-dd'T'HH:mm:ss'Z'"

const example = {
  from: '2021-01-01T00:00:00',
  to: '2021-01-02T23:59:59',
  items: [
    {
      type: 'timeseries',
      aggregation: 'count',
      interval: '1h'
    },
    {
      type: 'number',
      aggregation: 'count'
    }
  ]
}

class Query extends Component {
  state = {
    id: this.props.match.params.id,
    data: {},
    query: example,
    results: {},
    isLoading: false,
    isQuerying: false,
    isDeleting: false,
    error: false
  }

  componentDidMount() {
    if (this.state.id) {
      this.getEvent(this.state.id)
    }

    this.setState({
      query: this.getQueryWithRecentTime(example)
    })
  }

  getQueryWithRecentTime(query) {
    const { from, to } = getRelativeTimes(24 * 60)

    return { ...query, from: formatUTC(from), to: formatUTC(to) }
  }

  getEvent = id => {
    this.setState({ isLoading: true })

    console.log('get event', id)

    eventData.Get(id, (res, status) => {
      console.log('got event', res, status)
      this.setState({
        data: res,
        // query: this.getQueryWithRecentTime(example),
        isLoading: false,
        results: {}
      })

      if (res?.partitionKeys?.length === 0) {
        this.onQuery()
      }
    })
  }

  onSelectEvent = id => {
    this.setState({ id })
    this.getEvent(id)
  }

  onQuery = () => {
    const q = this.state.query

    this.setState({ isQuerying: true, error: false })

    // we change the date format to something golang likes
    // we use a different date format internally so we can have UTC inputs
    const q2 = {
      ...q,
      name: this.state.id,
      from: format(parseISO(q.from), timeFmt),
      to: format(parseISO(q.to), timeFmt)
    }

    eventData.Query(this.state.id, [q2], res => {
      if (!res || !res[0] || res.error) {
        this.setState({
          isQuerying: false,
          error: res[0]?.error || 'An error occurred'
        })
      } else {
        this.setState({
          isQuerying: false,
          results: res[0]
        })
      }
    })
  }

  render() {
    const { data, isLoading, isQuerying, error, query, results } = this.state

    return (
      <>
        <Header isLoading={isLoading} data={data} />
        <div className="wr">
          <Querying
            id={this.state.id}
            setId={this.onSelectEvent}
            data={data}
            createdAt={data?.stats?.oldest}
            isLoading={isQuerying}
            error={error}
            query={query}
            fields={data.fields}
            onQuery={this.onQuery}
            setQuery={query => this.setState({ query })}
          />
          {results && <Results query={query} data={results} />}
        </div>
      </>
    )
  }
}

export default Query

import React, { Component } from 'react'
import numeral from 'numeral'

import subscriptions from '../../data/models/subscription'
import Loading from '../../shared/loading'
import css from './subscription.module.css'
import Button from '../../shared/button'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

class Subscription extends Component {
  state = {
    isLoading: true,
    data: {},
    isLoadingPlans: true,
    plans: [],
    isGettingCheckoutUrl: false,
    checkoutUrl: '',
    isGettingPortalUrl: false,
    portalUrl: '',
    error: null
  }

  componentDidMount() {
    this.getData()
    this.getPlans()
  }

  getPlans = () => {
    this.setState({ isLoadingPlans: true })

    subscriptions.GetPlans(res => {
      this.setState({ isLoadingPlans: false, plans: res?.plans || [] })
    })
  }

  getData = () => {
    this.setState({ isLoading: true })

    subscriptions.Get(res => {
      if (res?.data?.subscription) {
        this.getPortalUrl()
      }

      this.setState({ isLoading: false, data: res })
    })
  }

  getCheckoutUrl = plan => {
    this.setState({ isGettingCheckoutUrl: true })

    subscriptions.GetCheckoutUrl(plan, res => {
      this.setState({ isGettingCheckoutUrl: false })

      if (res?.url) {
        window.location.href = res.url
      } else {
        this.setState({ error: res?.error || 'An unknown error occurred' })
      }
    })
  }

  getPortalUrl = () => {
    this.setState({ isGettingPortalUrl: true })

    subscriptions.GetPortalUrl(res => {
      this.setState({ isGettingPortalUrl: false })

      if (res?.url) {
        window.location.href = res?.url
      } else {
        this.setState({ error: res?.error || 'An unknown error occurred' })
      }
    })
  }

  render() {
    const {
      data,
      isLoading,
      isLoadingPlans,
      plans,
      isGettingPortalUrl,
      isGettingCheckoutUrl,
      error
    } = this.state

    if (isLoading || isLoadingPlans) {
      return (
        <div className="nestedNavContent">
          <Loading />
        </div>
      )
    }

    const quotas = data.org?.quotas || {}

    return (
      <div className="nestedNavContent">
        <div className="form">
          <h3>Manage Subscription</h3>
          <p>
            You can upgrade, downgrade, cancel, or start your subscription at
            any time. If you have a specific use-case which isn't supported well
            by our plans, please reach out to help@evenq.io. We're happy to
            build a custom plan for you, no matter how large or small.
          </p>
          {(quotas?.freeEventLimit > 0 || quotas?.freeQueryLimit > 0) && (
            <div className="notice">
              You have {numeral(quotas.freeEventLimit).format('0,0')} free
              events and {numeral(quotas.freeQueryLimit).format('0,0')} free
              queries until{' '}
              {format(parseISO(quotas.freeLimitsExpire), 'yyyy-MM-dd')} as part
              of our free sign-up bonus.
            </div>
          )}
        </div>
        {data?.subscription?.id ? (
          <>
            <h4 style={{ marginBottom: 3, color: 'green' }}>
              Active Subscription
            </h4>
            <div className={css.subscription}>
              <div className={css.meta}>
                <h3>{data.subscription.items[0].name}</h3>
                <span>
                  ${data.subscription.items[0].amount / 100} /{' '}
                  {data.subscription.items[0].interval}{' '}
                </span>
                <span>
                  &bull;{' '}
                  {numeral(data.org?.quotas?.planEventLimit).format('0,0')}{' '}
                  events and{' '}
                  {numeral(data.org?.quotas?.planQueryLimit).format('0,0')}{' '}
                  queries
                </span>
              </div>
              <Button loading={isGettingPortalUrl} onClick={this.getPortalUrl}>
                Manage Subscription
              </Button>
            </div>
            <h4
              style={{
                marginTop: 40,
                marginBottom: 3,
                color: 'var(--color-secondary)'
              }}
            >
              Other Plans
            </h4>
            <p style={{ margin: 0 }}>
              Click on "Manage Subscription" to switch to any of these plans.
            </p>
            <ul className={css.plans}>
              {plans
                .sort((a, b) => a.events - b.events)
                .map(p => (
                  <li key={p.name}>
                    <span>{p.name}</span>
                    <span>{numeral(p.events).format('0,0')} events</span>
                    <span>{numeral(p.queries).format('0,0')} queries</span>
                    <span className={css.price}>${p.amountMonth / 100}/mo</span>
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <ul className={css.plans}>
            {plans
              .sort((a, b) => a.events - b.events)
              .map(p => (
                <li key={p.name}>
                  <span>{p.name}</span>
                  <span>{numeral(p.events).format('0,0')} events</span>
                  <span>{numeral(p.queries).format('0,0')} queries</span>
                  <span className={css.price}>${p.amountMonth / 100}/mo</span>
                  <Button
                    onClick={() => this.getCheckoutUrl(p.id)}
                    loading={isGettingCheckoutUrl}
                  >
                    Checkout
                  </Button>
                </li>
              ))}
          </ul>
        )}
        {error && <div className="formError">{error}</div>}
      </div>
    )
  }
}

export default Subscription

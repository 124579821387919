import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import UserRoutes from './userRoutes'

import ToastHost from '../shared/toast'
import AlertHost from '../shared/alert'
import Footer from '../layout/footer'

// routes
import Login from '../pages/auth/login'
import Signup from '../pages/auth/signup'
import Forgot from '../pages/auth/forgot'
import Welcome from '../pages/auth/welcome'
import ResetPassword from '../pages/auth/resetPassword'
import Join from '../pages/auth/join'
import Unsubscribe from '../pages/auth/unsubscribe'
import Docs from '../pages/docs'

class Routes extends Component {
  componentDidMount() {
    this.handleChat()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.handleChat(prevProps)
    }
  }

  handleChat = () => {
    if (!window.$crisp) {
      return null
    }
  }

  render() {
    return (
      <div className="main">
        <Switch>
          {/* here we declare all the public routes, aka routes
            that can be accessed without being logged in */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/welcome" component={Welcome} />
          <Route exact path="/reset" component={ResetPassword} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/unsubscribe-changelog" component={Unsubscribe} />

          {/* public routes that dont require auth */}
          <Route path="/docs" component={Docs} />

          {/* if we didnt hit any routes, we check the routes
            that are only accesible by logged-in accounts */}
          <Route path="*" component={UserRoutes} />
        </Switch>
        <Footer location={this.props.location} />
        <ToastHost />
        <AlertHost />
      </div>
    )
  }
}

export default Routes

// API abstracts away some networking
// to reach our backend API
import 'whatwg-fetch'

import config from './config'
import user from './user'
import axios from 'axios'

export default class API {
  // performs a GET request
  static Get({ path, success, error }) {
    let status
    fetch(config.API_ROOT + path, {
      credentials: 'include',
      headers: {
        'x-evenq-org': user.GetOrg()
      }
    })
      .then(function (response) {
        status = response.status
        return response.json()
      })
      .then(function (json) {
        success(json, status)
      })
      .catch(function (ex) {
        error && error(null, status)
      })
  }

  // performs a POST request
  static Post({ path, data, success, error }) {
    let status
    fetch(config.API_ROOT + path, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'x-evenq-org': user.GetOrg()
      },
      body: JSON.stringify(data)
    })
      .then(function (response) {
        status = response.status
        return response.json()
      })
      .then(function (json) {
        success(json, status)
      })
      .catch(function (ex) {
        error && error(null, status)
      })
  }

  static PostProgress({ url, data, success, error, onProgress }) {
    const config = {
      onUploadProgress: progressEvent =>
        onProgress((progressEvent.loaded / progressEvent.total).toFixed(2))
    }

    axios
      .put(url, data, config)
      .then(res => success(res.data, res.status))
      .catch(err => error && error(null, err))
  }

  // performs a PATCH request
  static Patch({ path, data, success, error }) {
    let status
    fetch(config.API_ROOT + path, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'x-evenq-org': user.GetOrg()
      },
      body: JSON.stringify(data)
    })
      .then(function (response) {
        status = response.status
        return response.json()
      })
      .then(function (json) {
        success(json, status)
      })
      .catch(function (ex) {
        error && error(null, status)
      })
  }

  // performs a DELETE request
  static Delete({ path, data, success, error }) {
    let status
    fetch(config.API_ROOT + path, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'x-evenq-org': user.GetOrg()
      },
      body: JSON.stringify(data)
    })
      .then(function (response) {
        status = response.status
        return response.json()
      })
      .then(function (json) {
        success(json, status)
      })
      .catch(function (err) {
        error && error(null, status)
      })
  }
}

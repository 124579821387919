import React from 'react'
import classNames from 'classnames'

import css from './querying.module.css'
import Button from '../../../shared/button'

const operations = [
  {
    label: 'Equals',
    value: '='
  }
]

const Condition = ({ index, data, setCondition, onDelete }) => {
  return (
    <li className={css.row}>
      <div
        className={classNames(css.item, css.operator)}
        style={{ marginLeft: index === 0 ? 0 : 15 }}
      >
        {index === 0 ? 'WHERE' : 'AND'}
      </div>
      <div className={css.item}>
        <label>Field</label>
        <input
          type="text"
          value={data.key}
          onChange={e => setCondition({ ...data, key: e.target.value })}
        />
      </div>
      <div className={css.item}>
        <select
          value={data.op}
          onChange={e => setCondition({ ...data, op: e.target.value })}
        >
          {operations.map(op => (
            <option key={op.value} value={op.value}>
              {op.label}
            </option>
          ))}
        </select>
      </div>
      <div className={css.item}>
        <label>Value</label>
        <input
          type="text"
          value={data.value}
          onChange={e => setCondition({ ...data, value: e.target.value })}
        />
      </div>
      <Button small onClick={onDelete}>
        Delete
      </Button>
    </li>
  )
}

export default Condition

import React, { Component } from 'react'
import Button from '../../shared/button'
import toast from '../../data/toast'
import user from '../../data/user'

class AccountPassword extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    newRepeated: '',
    isSaving: false,
    error: ''
  }

  onUpdatePassword = e => {
    e.preventDefault()

    const data = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      newRepeated: this.state.newRepeated
    }

    if (!this.validateData(data)) {
      return
    }

    this.setState({
      isSaving: true
    })

    user.UpdatePassword(data, res => {
      this.setState({ isSaving: false })
      if (res.error) {
        this.setState({ error: res.error })
      } else {
        toast.open({ msg: 'Password Updated!', type: 'success' })
        this.setState({ oldPassword: '', newPassword: '', newRepeated: '' })
      }
    })
  }

  validateData = data => {
    let error = false

    if (!data.oldPassword) {
      error = 'Please enter your old password'
    } else if (!data.newPassword || data.newPassword.length < 8) {
      error = 'Your new password need to be at least 8 characters long'
    } else if (data.newPassword !== data.newRepeated) {
      error = 'The repeated password does not match the new password'
    }

    this.setState({ error: error })

    return error === false
  }

  render() {
    return (
      <div className="nestedNavContent">
        <div className="form">
          <h3>Change Password</h3>
          <div class="formRow" style={{ marginTop: 20 }}>
            <label>Old Password</label>
            <input
              type="password"
              value={this.state.oldPassword}
              onChange={e => this.setState({ oldPassword: e.target.value })}
            />
          </div>
          <div class="formRow">
            <label>New Password</label>
            <input
              type="password"
              value={this.state.newPassword}
              onChange={e => this.setState({ newPassword: e.target.value })}
            />
          </div>
          <div class="formRow">
            <label>Repeat New</label>
            <input
              type="password"
              value={this.state.newRepeated}
              onChange={e => this.setState({ newRepeated: e.target.value })}
            />
          </div>
          {this.state.error && (
            <div className="formError">{this.state.error}</div>
          )}
          <div className="formActions">
            <Button
              primary
              loading={this.state.isSaving}
              onClick={this.onUpdatePassword}
            >
              Update Password
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default AccountPassword

import React, { Component } from 'react'

import eventData from '../../../../data/models/events'
import Helmet from 'react-helmet'
import Subheader from '../../../../layout/header/subheader'

import Loading from '../../../../shared/loading'
import Code from './code'

class Settings extends Component {
  state = {
    id: this.props.match.params.id,
    data: {},
    isLoading: false,
    isSaving: false
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ isLoading: true })

    eventData.Get(this.state.id, res => {
      this.setState({
        data: res,
        isLoading: false
      })
    })
  }

  render() {
    const { data, isLoading } = this.state

    if (isLoading) {
      return <Loading />
    }

    return (
      <>
        <Helmet>
          <title>{data.name}</title>
        </Helmet>
        <Subheader
          parent={{
            title: 'Events',
            path: '/events/' + this.state.id
          }}
          title={isLoading ? 'Loading...' : data.name}
        />
        <div className="wr">
          <Code name={data.name} fields={data.fields} />
        </div>
      </>
    )
  }
}

export default Settings

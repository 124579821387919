import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Helmet from 'react-helmet'

import NestedNav from '../../shared/nav/nested'
import Subheader from '../../layout/header/subheader'
import Account from './account'
import AccountPassword from './password'
import AccountApiKey from './apikey'
import AccountTeam from './team'
import Teams from './teams'
import Notifications from './notifications'
import Subscription from './subscription'
import UsageHistory from './usage'

const nav = [
  {
    title: 'Profile',
    path: 'settings',
    items: [
      { title: 'Your Account', path: 'account' },
      { title: 'Password', path: 'password' }
    ]
  },
  {
    title: 'Subscription',
    path: 'settings',
    items: [
      { title: 'Subscription', path: 'subscription' },
      { title: 'Usage', path: 'usage' }
    ]
  },
  {
    title: 'Team',
    path: 'settings',
    items: [{ title: 'Manage Teams', path: 'teams' }]
  },
  {
    title: 'Notifications',
    path: 'settings',
    items: [{ title: 'Email Reports', path: 'reports' }]
  },
  {
    title: 'API',
    path: 'settings',
    items: [{ title: 'Authentication', path: 'api' }]
  }
]

class Settings extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Settings</title>
        </Helmet>
        <Subheader />
        <div className="wr">
          <NestedNav
            title="Settings"
            active={this.props.location.pathname}
            data={nav}
          />
          <Switch>
            <Route exact path="/settings/account" component={Account} />
            <Route
              exact
              path="/settings/password"
              component={AccountPassword}
            />
            <Route exact path="/settings/reports" component={Notifications} />
            <Route exact path="/settings/api" component={AccountApiKey} />
            <Route exact path="/settings/teams" component={Teams} />
            <Route exact path="/settings/teams/:id" component={AccountTeam} />
            <Route exact path="/settings/usage" component={UsageHistory} />
            <Route
              exact
              path="/settings/subscription"
              component={Subscription}
            />
          </Switch>
        </div>
      </React.Fragment>
    )
  }
}

export default Settings

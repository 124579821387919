import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import user from '../data/user.js'

import Header from '../layout/header'
import NotFound from '../layout/notfound.js'

import EventsHost from '../pages/events'

import Settings from '../pages/settings/index.js'
import Events from '../pages/events/events'
import Query from '../pages/query'

class UserRoutes extends Component {
  componentDidMount() {
    this.checkAuth()
  }

  // once the user routes component mounts we check if
  // there is a valid user session. If not, we redirect
  // to the login or signup page
  checkAuth = () => {
    const u = user.Get()
    if (!u.id) {
      this.props.history.replace('/login')
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header pathname={this.props.location.pathname} />
        <Switch>
          <Route exact path="/" component={Events} />
          <Route path="/events" component={EventsHost} />
          <Route path="/query/:id" component={Query} />
          <Route path="/query" component={Query} />
          <Route path="/settings" component={Settings} />

          {/* if nothing has matched by now, it's a 404 */}
          <Route component={NotFound} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default UserRoutes

import React, { Component } from 'react'

import NestedNav from '../../shared/nav/nested'

const content = [
  {
    title: 'Getting Started',
    path: 'getting-started',
    items: [
      { title: 'Basics', path: 'basics' },
      { title: 'Authentication', path: 'authentication' },
      { title: 'Your First Event', path: 'first-event' }
    ]
  },
  {
    title: 'Send Events',
    path: 'send-events',
    items: [
      { title: 'HTTP', path: 'http-post' },
      { title: 'Go', path: 'golang' },
      { title: 'Node.js', path: 'nodejs' }
    ]
  },
  {
    title: 'Queries',
    path: 'queries',
    items: [
      { title: 'Basics', path: 'basics' },
      { title: 'Reference', path: 'reference' }
    ]
  },
  {
    title: 'Learn',
    path: 'learn',
    items: [
      { title: 'Import Data', path: 'import-data' },
      { title: 'Naming conventions', path: 'naming-conventions' },
      { title: 'Partition Keys', path: 'partition-keys' },
      { title: 'Use cases', path: 'use-cases' },
      { title: 'Pricing', path: 'pricing' },
      { title: 'API Limitations', path: 'api-limitations' }
    ]
  }
]

class NavBar extends Component {
  render() {
    const active = this.props.location.pathname

    return (
      <NestedNav
        title="Documentation"
        base="docs"
        data={content}
        active={active}
      />
    )
  }
}

export default NavBar

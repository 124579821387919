import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import Track from './track'
import Routes from './routes'
import user from '../data/user'
import Loading from '../shared/loading'

class RouterHost extends Component {
  state = {
    isCheckingAuth: true
  }

  componentDidMount() {
    this.checkAuth()
  }

  checkAuth = () => {
    user.CheckAuth(() => {
      this.setState({ isCheckingAuth: false })
    })
  }

  render() {
    if (this.state.isCheckingAuth) {
      return <Loading />
    }

    return (
      <BrowserRouter>
        <Track>
          <Route path="/" component={Routes} />
        </Track>
      </BrowserRouter>
    )
  }
}

export default RouterHost

import React, { useState } from 'react'
import CountUp from 'react-countup'

import Stats from './stats'
import Chart from './chart'

import List from './list'
import Highlighter from '../../../shared/highlighter'

import css from './results.module.css'

const Results = ({ data }) => {
  const [lineIndex, setLineIndex] = useState(0)

  if (!data || !data.results) {
    return null
  }

  return (
    <div className={css.results}>
      <h2>Results</h2>
      {data.stats && <Stats stats={data.stats} />}
      <ul className={css.items}>
        {data.query?.items &&
          data.query.items.map((item, index) => (
            <li key={index}>
              <header className={css.header}>
                <span className={css.itemIndex}>{index}</span> {item.type}{' '}
                {item.aggregation}
              </header>
              {item.type === 'timeseries' &&
                (data.results[index] ? (
                  <div style={{ height: 200 }}>
                    <Chart
                      lineIndex={lineIndex}
                      setLineIndex={setLineIndex}
                      data={data.results[index]}
                    />
                  </div>
                ) : (
                  <div>no data</div>
                ))}
              {item.type === 'number' && (
                <CountUp end={data.results[index]} separator="," />
              )}
              {item.type === 'list' && <List data={data.results[index]} />}
            </li>
          ))}
      </ul>
      {data && data.results && (
        <Highlighter
          code={JSON.stringify(
            { ...data, results: ['... omitted ...'] },
            '',
            '  '
          )}
          lang="json"
        />
      )}
    </div>
  )
}

export default Results

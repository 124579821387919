import React, { Component } from 'react'

import Button from '../button'

import './empty.css'

class EmptyState extends Component {
  render() {
    const { title, description, cta } = this.props

    let btn = null

    if (cta) {
      if (cta.url) {
        btn = <Button to={cta.url}>{cta.title}</Button>
      } else if (cta.onClick) {
        btn = <Button onClick={cta.onClick}>{cta.title}</Button>
      }
    }

    return (
      <div className="empty">
        <h2>{title}</h2>
        <p>{description}</p>
        {btn}
      </div>
    )
  }
}

export default EmptyState

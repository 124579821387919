import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import emitter from 'tiny-emitter/instance'

import Logotype from '../../../images/icon'

import Overlay from '../../shared/overlay'

import user from '../../data/user'
import Button from '../../shared/button'

import './styles.css'
import Usage from '../../shared/usage'
import OrgSwitcher from './orgs'
import VerifyBanner from './verify'

class Header extends Component {
  state = {
    u: user.Get(),
    isLoggingOut: false,
    isVerified: false
  }

  componentDidMount() {
    emitter.on('user.verified', this.handleVerifyEvent)
  }

  componentWillUnmount() {
    emitter.off('user.verified', this.handleVerifyEvent)
  }

  handleVerifyEvent = data => {
    if (data.userId === this.state.u.id) {
      this.setState({ isVerified: true })
    }
  }

  onLogout = () => {
    this.setState({ isLoggingOut: true })

    user.Logout(() => {
      window.location = '/login'
    })
  }

  render() {
    const { u, isVerified, isLoggingOut } = this.state

    const isLoggedIn = u.id
    const name = u.name || u.email || '👋🏻'
    const symbols = [...name]

    const trigger = (
      <div className="headerProfile">{symbols[0].toUpperCase()}</div>
    )

    return (
      <header className="header">
        {isLoggedIn && !u.isVerified && !isVerified && <VerifyBanner />}
        <div className="wr">
          <Link to="/" className="logoLink">
            <h1 className="logo">
              <Logotype />
            </h1>
          </Link>
          <nav>
            <Link to="/">Events</Link>
            <Link to="/query">Query</Link>
          </nav>
          <div className="spacer" />
          <nav className="secondary">
            <Link to="/docs">Documentation</Link>
            {!isLoggedIn && (
              <React.Fragment>
                <Button>Log in</Button>
                <Button>Sign up</Button>
              </React.Fragment>
            )}
          </nav>
          {isLoggedIn && (
            <Overlay trigger={trigger} position="bottom-left">
              <OrgSwitcher />
              <ul className="overlay__options">
                <li>
                  <Link to="/settings/account">Settings</Link>
                </li>
                <li className="divider" />
                <li>
                  <Button onClick={this.onLogout} loading={isLoggingOut}>
                    Logout
                  </Button>
                </li>
              </ul>
              <Usage />
            </Overlay>
          )}
        </div>
      </header>
    )
  }
}

export default Header

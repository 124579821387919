import React from 'react'
import numeral from 'numeral'
import { Link } from 'react-router-dom'

import Subheader from '../../layout/header/subheader'
import Loading from '../../shared/loading'

import css from './header.module.css'

const Header = ({ isLoading, data = {} }) => {
  if (isLoading) {
    return (
      <Subheader parent={{ title: 'Events', path: '/' }} title="Loading...">
        <div style={{ marginLeft: 'auto' }}>
          <Loading small />
        </div>
      </Subheader>
    )
  }

  const { stats, partitionKeys, hasMorePKs } = data
  const pkCount = partitionKeys ? partitionKeys.length : 0

  return (
    <Subheader parent={{ title: 'Events', path: '/' }} title="Query">
      <span className={css.info}>
        <Link to="/docs/learn/partition-keys">
          {pkCount !== 0 || hasMorePKs ? (
            <span>
              {numeral(pkCount || 1).format('0,0')}
              {hasMorePKs && '+'} partition
              {pkCount !== 1 ? 's' : ''}
            </span>
          ) : (
            `Not Partitioned`
          )}
        </Link>{' '}
        &bull;{' '}
        <strong>
          {numeral(stats?.totalCount || 0).format('0,0')}
          {stats?.isIncomplete && '+'} Events
        </strong>
      </span>
    </Subheader>
  )
}

export default Header

import React, { Component } from 'react'
import Helmet from 'react-helmet'

import RouterHost from './router'
import { timeKeeper } from './shared/time'

import './shared/switch.css'

class App extends Component {
  componentDidMount() {
    // start timekeeper, see inside for comment
    timeKeeper()
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          {/* we set the default title here so it always goes back to this one instead 
              of being stuck on whatever previous title we had */}
          <title>Evenq</title>
        </Helmet>
        <RouterHost />
      </React.Fragment>
    )
  }
}

export default App

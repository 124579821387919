class Toast {
  // notify the callback when we have a new toast
  bindEvents(cb) {
    this.cb = cb
  }

  // data format: { msg: '...', type: 'success|error' }
  open({ msg, type, timeout, link }) {
    this.cb && this.cb({ msg, type, timeout, link })
  }
}

export default new Toast()

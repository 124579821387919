import API from '../api'
import addMinutes from 'date-fns/addMinutes'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'

// the number of minutes after the creation time
// for which the metric is considered to be new
const minWhileNew = 5

export const isNewEvent = function (createdAt) {
  // determine the date at which the metric becomes "old"
  // by adding 5 minutes to the current time. parse-fns
  const futureDate = addMinutes(parseISO(createdAt), minWhileNew)
  return isBefore(new Date(), futureDate)
}

// returns the time after which a metric is no longer considered new
export const timeOnceOld = function (createdAt) {
  return addMinutes(parseISO(createdAt), minWhileNew)
}

export default class Events {
  static GetList(cb) {
    API.Get({
      path: '/events',
      success: cb,
      error: cb
    })
  }

  static Get(id, cb) {
    API.Get({
      path: '/events/' + id,
      success: cb,
      error: cb
    })
  }

  static Query(id, query, cb) {
    API.Post({
      path: '/events/' + id + '/query',
      data: query,
      success: cb,
      error: cb
    })
  }

  static Delete(id, cb) {
    API.Delete({
      path: '/events/' + id,
      success: cb,
      error: cb
    })
  }

  static Create(data, cb) {
    API.Post({
      path: '/events',
      data: data,
      success: cb,
      error: cb
    })
  }

  static Update(id, data, cb) {
    API.Patch({
      path: '/events/' + id,
      data: data,
      success: cb,
      error: cb
    })
  }

  static GetImports(eid, cb) {
    API.Get({
      path: '/events/' + eid + '/imports',
      success: cb,
      error: cb
    })
  }

  static CreateImport(id, data, cb) {
    API.Post({
      path: '/events/' + id + '/imports',
      data: data,
      success: cb,
      error: cb
    })
  }

  static StartImport(eid, iid, data, cb) {
    API.Post({
      path: '/events/' + eid + '/imports/' + iid + '/start',
      data: data,
      success: cb,
      error: cb
    })
  }

  static SetFavorite(id, isFav, cb) {
    API.Patch({
      path: '/events/' + id + '/favorite',
      data: {
        isFavorite: isFav
      },
      success: cb,
      error: cb
    })
  }
}

import API from '../api'

export default class Subscription {
  static Get(cb) {
    API.Get({
      path: '/subscriptions',
      success: cb,
      error: cb
    })
  }

  static GetPlans(cb) {
    API.Get({
      path: '/subscriptions/plans',
      success: cb,
      error: cb
    })
  }

  static GetCheckoutUrl(plan, cb) {
    API.Get({
      path: '/subscriptions/checkout/' + plan,
      success: cb,
      error: cb
    })
  }

  static GetPortalUrl(cb) {
    API.Get({
      path: '/subscriptions/portal',
      success: cb,
      error: cb
    })
  }
}

import React, { Component } from 'react'
import numeral from 'numeral'
import parseISO from 'date-fns/parseISO'
import formatDistanceStrict from 'date-fns/esm/formatDistanceStrict'
import format from 'date-fns/esm/format'

import EmptyState from '../../../../shared/empty'

const getLabelClass = status => {
  const options = {
    done: 'label label--good',
    processing: 'label label--public'
  }

  return options[status] || 'label'
}

class History extends Component {
  render() {
    const { id, data } = this.props

    const filteredData = data?.filter(item => item.status !== 'pending')

    if (!data || filteredData.length === 0) {
      return (
        <EmptyState
          title="No Imports"
          description="You have not imported any data for this event."
          cta={{
            title: 'Start Importing Data',
            url: '/events/' + id + '/imports/new'
          }}
        />
      )
    }

    return (
      <>
        <h4 className="listTitle">Import History</h4>
        <div className="list withTitle">
          {filteredData.map(item => (
            <div className="listItem" key={item.id}>
              <span className="listItem__title">
                {item.fileName}{' '}
                <span className={getLabelClass(item.status)}>
                  {item.status}
                </span>
              </span>
              <div className="spacer" />
              <div className="listItem__meta">
                <span className="secondary">
                  {item.completedAt
                    ? 'Completed'
                    : item.startedAt
                    ? 'Started'
                    : 'Created'}
                </span>
                <span className="primary">
                  {item.completedAt
                    ? format(parseISO(item.completedAt), 'MMM do HH:mm:ss')
                    : item.startedAt
                    ? format(parseISO(item.startedAt), 'MMM do HH:mm:ss')
                    : format(parseISO(item.createdAt), 'MMM do HH:mm:ss')}
                </span>
              </div>
              <div className="listItem__meta">
                <span className="secondary">Duration</span>
                <span className="primary">
                  {item.startedAt && item.completedAt
                    ? formatDistanceStrict(
                        parseISO(item.startedAt),
                        parseISO(item.completedAt)
                      )
                    : 'N/A'}
                </span>
              </div>
              <div className="listItem__meta">
                <span className="secondary">Event Count</span>
                <span className="primary">
                  {item.status === 'processing'
                    ? 'Pending'
                    : numeral(item.eventCount).format('0,0')}
                </span>
              </div>
              <div className="listItem__meta">
                <span className="secondary">File size</span>
                <span className="primary">
                  {numeral(item.fileSize).format('0,0b')}
                </span>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default History

import React, { Component } from 'react'
import ReactSelect from 'react-select'

const customStyles = ({
  width,
  menuStyle,
  disableChevron,
  textAlign,
  noBorder
}) => ({
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    borderRadius: 3,
    color: 'var(--color)',
    backgroundColor: 'transparent',
    boxShadow: noBorder
      ? 'none'
      : state.isFocused
      ? 'inset 0 0 0 1px rgb(100, 153, 255) !important;'
      : 'inset 0 0 0 1px var(--btn-border)',
    width: width || 200,
    height: 24,
    minHeight: 24,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #ffffff52'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: provided => {
    if (disableChevron) {
      return {
        display: 'none'
      }
    }
    return {
      ...provided,
      padding: 5,
      color: 'var(--color-secondary)',
      marginTop: -4,
      width: 25
    }
  },
  menu: provided => ({
    ...provided,
    textAlign: textAlign || 'left',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'var(--bg-overlay)',
    ...menuStyle
  }),
  valueContainer: provided => ({
    ...provided,
    justifyContent: textAlign === 'right' ? 'flex-end' : 'flex-start'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'var(--color)',
    height: 24,
    lineHeight: '18px'
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    color: state.isSelected ? 'var(--color-action)' : 'var(--color)',
    backgroundColor: state.isFocused
      ? 'var(--bg-input-hover)'
      : 'var(--bg-overlay)',
    '&:hover': {
      backgroundColor: 'var(--bg-input-hover)'
    }
  })
})

class Select extends Component {
  onChange = val => {
    // prevent non-clearable from being cleared
    if (!this.props.isClearable && !val) {
      return
    }

    if (this.props.isMulti) {
      this.props.onChange(val.map(v => v.value))
    } else {
      this.props.onChange(val.value)
    }
  }

  render() {
    const {
      options,
      value,
      isMulti,
      isClearable,
      width,
      isSearchable,
      disableChevron,
      textAlign,
      placeholder,
      noBorder,
      menuStyle,
      menuIsOpen
    } = this.props

    let selected
    if (isMulti) {
      selected = options.filter(opt => opt.value === value)
    } else {
      selected = options.find(opt => value === opt.value)
    }

    const styleSettings = {
      width,
      disableChevron,
      textAlign,
      noBorder,
      menuStyle
    }

    return (
      <ReactSelect
        options={options}
        value={selected}
        onChange={this.onChange}
        isMulti={isMulti}
        placeholder={placeholder || 'Select...'}
        isClearable={isClearable}
        isSearchable={isSearchable || false}
        styles={customStyles(styleSettings)}
        menuIsOpen={menuIsOpen}
      />
    )
  }
}

export default Select

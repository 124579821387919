import API from '../api'
import user from '../user'

export default class Org {
  static Usage(cb) {
    const id = user.GetOrg()
    API.Get({
      path: '/orgs/' + id + '/usage',
      success: cb,
      error: cb
    })
  }

  static Get(id, cb) {
    API.Get({
      path: '/orgs/' + id,
      success: cb,
      error: cb
    })
  }

  static GetMembers(id, cb) {
    API.Get({
      path: '/orgs/' + id + '/members',
      success: cb,
      error: cb
    })
  }

  static Update(id, data, cb) {
    API.Patch({
      path: '/orgs/' + id,
      data: data,
      success: cb,
      error: cb
    })
  }

  static UpdateNotificationPref(id, data, cb) {
    API.Patch({
      path: '/orgs/' + id + '/notifications',
      data: data,
      success: cb,
      error: cb
    })
  }

  static GetList(cb) {
    API.Get({
      path: '/orgs',
      success: cb,
      error: cb
    })
  }

  static Invite(id, email, cb) {
    API.Post({
      path: '/orgs/' + id + '/invite',
      data: { email },
      success: cb,
      error: cb
    })
  }

  static GetAPIKey(cb) {
    const id = user.GetOrg()

    API.Get({
      path: '/orgs/' + id + '/apikey',
      success: cb,
      error: cb
    })
  }

  static SetOnboardingComplete(cb) {
    const id = user.GetOrg()

    API.Post({
      path: '/orgs/' + id + '/onboardingcomplete',
      success: cb,
      error: cb
    })
  }

  static RemoveUser(id, uid, cb) {
    API.Delete({
      path: '/orgs/' + id + '/members/' + uid,
      success: cb,
      error: cb
    })
  }

  static UpdateAdmin(id, uid, isAdmin, cb) {
    API.Patch({
      path: '/orgs/' + id + '/members/' + uid,
      data: { isAdmin },
      success: cb,
      error: cb
    })
  }
}

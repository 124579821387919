import React, { Component } from 'react'
import History from './history'
import Helmet from 'react-helmet'
import Subheader from '../../../../layout/header/subheader'
import eventData from '../../../../data/models/events'
import Loading from '../../../../shared/loading'
import Button from '../../../../shared/button'

class Imports extends Component {
  state = {
    id: this.props.match.params.id,
    data: {},
    history: [],
    isLoading: true,
    isLoadingHistory: true
  }

  componentDidMount() {
    this.getData()
    this.getHistory()
  }

  getData = () => {
    eventData.Get(this.state.id, res => {
      this.setState({
        data: res,
        isLoading: false
      })
    })
  }

  getHistory = () => {
    this.setState({ isLoadingHistory: true })
    eventData.GetImports(this.state.id, res => {
      this.setState({ history: res, isLoadingHistory: false })
    })
  }

  render() {
    const { id, history, data, isLoading, isLoadingHistory } = this.state

    if (isLoading || isLoadingHistory) {
      return <Loading />
    }

    return (
      <>
        <Helmet>
          <title>{id} Import History</title>
        </Helmet>
        <Subheader
          parent={{
            title: data.id,
            path: '/'
          }}
          title="Import History"
        >
          <Button to={'/events/' + data.id + '/imports/new'}>New Import</Button>
        </Subheader>
        <div className="wr">
          <History id={id} data={history} />
        </div>
      </>
    )
  }
}

export default Imports

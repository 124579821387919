import config from './config'
import Pusher from 'pusher-js';

export default class PusherClient {
  static Get(endpoint) {
    Pusher.logToConsole = false;

    // send cookies
    Pusher.Runtime.createXHR = function() {
      var xhr = new XMLHttpRequest()
      xhr.withCredentials = true
      return xhr
    }

    return new Pusher(config.PUSHER_APP_KEY, {
      authEndpoint: config.API_ROOT + endpoint,
      cluster: 'mt1',
      forceTLS: true
    })
  }
}

import React, { Component } from 'react'
import update from 'immutability-helper'

import Org from '../../data/models/org'
import Loading from '../../shared/loading'
import Select from '../../shared/select/select'
import toast from '../../data/toast'

const options = [
  { label: 'None', value: 'none' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' }
]

class Notifications extends Component {
  state = {
    data: [],
    isLoading: false
  }

  componentDidMount() {
    this.getData()
  }

  updatePref = (index, value) => {
    const data = this.state.data
    const id = data[index].id
    const oldPref = data[index].notificationPref || {}
    const newPref = update(oldPref, {
      favReport: { $set: value }
    })

    this.setState({
      data: update(data, {
        [index]: {
          notificationPref: { $set: newPref }
        }
      })
    })

    Org.UpdateNotificationPref(id, newPref, res => {
      toast.open({ msg: 'Preferences updated', type: 'success' })
    })
  }

  getData = () => {
    this.setState({ isLoading: true })

    Org.GetList(res => {
      this.setState({
        isLoading: false,
        data: res
      })
    })
  }

  render() {
    const { isLoading, data } = this.state

    return (
      <div className="nestedNavContent">
        <div className="form">
          <h3>Manage Email Reports</h3>
          <p>
            You can manage your email notifications for each team separately.
            Right now we only support weekly and daily reports of your favorite
            events (favorites are shared across a team).
          </p>
          <div className="list">
            {isLoading ? (
              <Loading />
            ) : (
              data.map((org, i) => (
                <div className="listItem" key={i}>
                  <span className="listItem__title">{org.name}</span>
                  <div className="spacer" />
                  <Select
                    options={options}
                    value={org.notificationPref.favReport || 'none'}
                    onChange={val => this.updatePref(i, val)}
                    noBorder
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Notifications

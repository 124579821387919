import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Org from '../../data/models/org'
import Loading from '../../shared/loading'

class Teams extends Component {
  state = {
    isLoading: false,
    orgs: []
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ isLoading: true })

    Org.GetList(res => {
      this.setState({
        isLoading: false,
        orgs: res
      })
    })
  }

  render() {
    const { isLoading, orgs } = this.state

    return (
      <div className="nestedNavContent">
        <div className="form">
          <h3>Your Teams</h3>
          <p>These are all the teams you have access to.</p>
          <div className="list">
            {isLoading ? (
              <Loading />
            ) : (
              orgs.map(org => (
                <div className="listItem">
                  <Link
                    to={'/settings/teams/' + org.id}
                    className="listItem__title"
                  >
                    {org.name}
                  </Link>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Teams

import API from '../api'

// This class is for the currently logged-in user
export default class Changelog {
  static Unsubscribe(email, cb) {
    API.Post({
      path: '/changelog/unsubscribe',
      data: {
        email: email
      },
      success: cb,
      error: cb
    })
  }
}

import React from 'react'
import numeral from 'numeral'

import css from './results.module.css'

const Stats = ({ stats = {} }) => {
  const { totalTime, eventsParsed, meps } = stats
  return (
    <div className={css.stats}>
      Analyzed {numeral(eventsParsed).format('0,0')} Events in{' '}
      {numeral(totalTime / 1000).format('0,0.0')}s ({meps} Million Events Per
      Second)
    </div>
  )
}

export default Stats

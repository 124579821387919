import React, { Component } from 'react'
import isHotkey from 'is-hotkey'

import alert from '../data/alert'
import Modal from './modal'
import Button from './button'

class AlertHost extends Component {
  state = {
    alert: {},
    isConfirming: false
  }

  componentDidMount() {
    alert.bindEvents(this.onAlert)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey)
  }

  handleKey = e => {
    if (isHotkey('esc', e)) {
      this.hide()
    } else if (isHotkey('return', e)) {
      if (this.state.alert.onConfirm) {
        this.onConfirm()
      }
    }
  }

  onAlert = alert => {
    this.setState({
      alert: alert
    })
    document.addEventListener('keydown', this.handleKey)
  }

  hide = () => {
    this.setState({ alert: {} })
    document.removeEventListener('keydown', this.handleKey)
  }

  onConfirm = () => {
    this.setState({ isConfirming: true })
    this.state.alert.onConfirm(this.onCompleteConfirm)
  }

  onCompleteConfirm = () => {
    this.setState({
      isConfirming: false,
      alert: {}
    })
    document.removeEventListener('keydown', this.handleKey)
  }

  render() {
    const alert = this.state.alert
    let actions = []

    if (!alert.text) {
      return null
    }

    if (alert.onConfirm) {
      actions.push(
        <Button
          onClick={this.onConfirm}
          primary={true}
          loading={this.state.isConfirming}
        >
          {alert.confirmText || 'Confirm'}
        </Button>
      )
    }

    return (
      <Modal
        show={true}
        title={alert.title}
        width={300}
        onClose={this.hide}
        actions={actions}
        closeLabel={alert.onConfirm === undefined ? 'Close' : 'Cancel'}
      >
        {alert.text}
      </Modal>
    )
  }
}

export default AlertHost

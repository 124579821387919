import React from 'react'
import numeral from 'numeral'

import css from './results.module.css'

const List = ({ data }) => {
  return (
    <ul className={css.list}>
      {data &&
        data.map(item => (
          <li key={item.label}>
            <label>{item.label}</label>
            <span>{numeral(item.value).format('0,0')}</span>
          </li>
        ))}
    </ul>
  )
}

export default List

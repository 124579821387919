import React, { Component } from 'react'

import Button from '../../../shared/button'

import Items from './items'
import Conditions from './conditions'

import css from './querying.module.css'

class Querying extends Component {
  render() {
    const { id, setId, data, query, isLoading, error } = this.props

    const fields = []

    return (
      <div className={css.wrapper}>
        <Conditions
          id={id}
          setId={setId}
          partitionList={data.partitionKeys}
          hasMorePKs={data.hasMorePKs}
          fields={fields || []}
          query={query}
          setQuery={this.props.setQuery}
        />
        <h3>Queries</h3>
        <Items
          fields={fields}
          items={query.items}
          setItems={items =>
            this.props.setQuery({ ...this.props.query, items })
          }
        />
        {error && <div className="formError">{error}</div>}
        <Button onClick={this.props.onQuery} loading={isLoading} primary>
          Run Query
        </Button>
      </div>
    )
  }
}

export default Querying

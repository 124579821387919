import React from 'react'

import css from './import.module.css'
import Loading from '../../../../shared/loading'

const Upload = ({ onFileChange, progress }) => {
  return (
    <div className={css.upload}>
      {progress !== 0 && (
        <div
          className={css.uploadBar}
          style={{ width: progress * 100 + '%' }}
        />
      )}
      <div className={css.uploadContent}>
        {progress === 0 && (
          <input type="file" accept="text/csv" onChange={onFileChange} />
        )}
        {progress >= 1 ? (
          <span>File Upload Complete ✓</span>
        ) : (
          progress !== 0 && (
            <>
              <Loading small noFlex />
              <span> Uploading... {(progress * 100).toFixed(0)}%</span>
            </>
          )
        )}
      </div>
    </div>
  )
}

export default Upload

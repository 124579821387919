class Alert {
  // notify the callback when we have a new Alert
  bindEvents(cb) {
    this.cb = cb
  }

  alert({ title, text }) {
    this.cb && this.cb({ title, text })
  }

  confirm({ title, text, onConfirm, confirmText }) {
    this.cb && this.cb({ title, text, onConfirm, confirmText })
  }
}

export default new Alert()

import React from 'react'
import classNames from 'classnames'

import Button from '../../../shared/button'

import css from './querying.module.css'

const modes = [
  { label: 'Number', value: 'number' },
  { label: 'Timeseries', value: 'timeseries' },
  { label: 'List', value: 'list' }
]

const aggregations = [
  { label: 'Sum', value: 'sum' },
  { label: 'Count', value: 'count' },
  { label: 'Unique Count', value: 'count_unique' },
  { label: 'Min', value: 'min' },
  { label: 'Max', value: 'max' },
  { label: 'Avg', value: 'avg' }
]

const sortOrders = [
  { label: 'None', value: undefined },
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' }
]

const Item = ({ index, item, setItem, onDelete }) => {
  return (
    <li className={css.row}>
      <div className={classNames(css.item, css.itemIndex, css.green)}>
        {index}
      </div>
      <div className={css.item}>
        <label>Type</label>
        <select
          value={item.type}
          onChange={e => setItem({ ...item, type: e.target.value })}
        >
          {modes.map(mod => (
            <option key={mod.value} value={mod.value}>
              {mod.label}
            </option>
          ))}
        </select>
      </div>
      <div className={css.item}>
        <label>Aggregate</label>
        <select
          value={item.aggregation}
          onChange={e => setItem({ ...item, aggregation: e.target.value })}
        >
          {aggregations.map(agg => (
            <option key={agg.value} value={agg.value}>
              {agg.label}
            </option>
          ))}
        </select>
      </div>
      {(item.aggregation !== 'count' || item.type === 'list') && (
        <div className={css.item}>
          <label>Field</label>
          <input
            type="text"
            value={item.key}
            onChange={e => setItem({ ...item, key: e.target.value })}
          />
        </div>
      )}
      {item.type === 'timeseries' && (
        <div className={css.item}>
          <label>Interval</label>
          <input
            className={css.input}
            type="text"
            value={item.interval}
            onChange={e => setItem({ ...item, interval: e.target.value })}
          />
        </div>
      )}
      {item.type === 'list' && (
        <>
          <div className={css.item}>
            <label>Sort</label>
            <select
              value={item.listOrder}
              onChange={e => setItem({ ...item, listOrder: e.target.value })}
            >
              {sortOrders.map(ord => (
                <option key={ord.value} value={ord.value}>
                  {ord.label}
                </option>
              ))}
            </select>
          </div>
          <div className={css.item}>
            <label>Max. Results</label>
            <input
              className={css.input}
              type="number"
              value={item.listLimit}
              onChange={e =>
                setItem({ ...item, listLimit: Number(e.target.value) })
              }
              style={{ width: 50 }}
            />
          </div>
          <div className={css.item}>
            <label>
              Exclude Null Values
              <input
                type="checkbox"
                className={item.listExcludeNull ? 'checked' : null}
                checked={item.listExcludeNull}
                onChange={e =>
                  setItem({ ...item, listExcludeNull: e.target.checked })
                }
                style={{ marginLeft: 6 }}
              />
            </label>
          </div>
        </>
      )}
      <Button onClick={onDelete} small>
        Remove
      </Button>
    </li>
  )
}

export default Item

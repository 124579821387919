import React, { Component } from 'react'

import './overlay.css'
import './settings.css'

class Overlay extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
  }

  state = {
    isOpen: false
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onOutsideClick, false)
  }

  onOutsideClick = e => {
    if (!e) {
      return
    }

    if (this.node.current && this.node.current.contains(e.target)) {
      return
    }

    this.props.onChange && this.props.onChange(false)
    this.close()
  }

  onShow = e => {
    if (!this.state.isOpen) {
      this.props.onChange && this.props.onChange(true)
      this.open()
    } else {
      this.close()
    }
  }

  close = () => {
    this.setState({ isOpen: false })
    document.removeEventListener('mouseup', this.onOutsideClick, false)
  }

  open = () => {
    this.setState({ isOpen: true })
    document.addEventListener('mouseup', this.onOutsideClick, false)
  }

  render() {
    let style = {}
    if (this.props.width) {
      style.width = this.props.width
    }
    if (this.props.height) {
      style.height = this.props.height
    }

    let classes = 'overlay'
    if (this.props.position) {
      classes += ' ' + this.props.position
    }

    let isOpen = this.state.isOpen
    if (this.props.isOpen !== undefined) {
      isOpen = this.props.isOpen
    }

    return (
      <div ref={this.node} className="overlayWrapper">
        <div onClick={this.onShow} className="overlayTrigger">
          {this.props.trigger}
        </div>
        {isOpen && (
          <div className={classes} style={style}>
            {this.props.children}
            {this.props.actions && (
              <ul className="overlay__nav">
                {this.props.actions.map(action => (
                  <li
                    key={action.id}
                    onClick={() => this.props.onNavigate(action.id)}
                    className={this.props.active === action.id ? 'active' : ''}
                  >
                    {action.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Overlay

import React from 'react'
import * as d3 from 'd3'
import numeral from 'numeral'
import throttle from 'lodash.throttle'

import './styles.css'

const strokeWidth = 1.5

class Line extends React.Component {
  constructor(props) {
    super(props)
    this.throttlePosLine = throttle(this.reposLine, 16)
    this.parentEl = React.createRef()
  }

  state = {
    // elements we draw
    focusLine: null,
    svg: null,
    // maximum value accross all lines
    max: 0,
    // holds parsed data
    data: [],
    margin: {
      top: 15,
      right: 30,
      bottom: 20,
      left: 40
    }
  }

  componentDidMount() {
    // determine initial size
    const width = this.props.width || this.parentEl.current.clientWidth
    const height = this.props.height || this.parentEl.current.clientHeight

    if (!!this.props.margin) {
      this.setState({ margin: this.props.margin })
    }

    // set dimensions to state and continue in callback
    this.setState({ width, height }, () => {
      // we pass drawChart as a callback to getMax
      // because it doesn't make sense to call drawChart
      // inside of getMax (separation of concerns)
      this.getMax(this.drawChart)
    })

    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    const width = this.parentEl.current.clientWidth
    const height = this.parentEl.current.clientHeight

    if (width !== this.state.width || height !== this.state.height) {
      this.setState({ width, height }, this.drawChart)
    }
  }

  componentDidUpdate(prevProps) {
    // the chartSize is used by the parent to communicate
    // size changes that are not based on window resizes,
    // such was changes to dashboard elements. The chartSize
    // value is completely arbitrary and not used by this
    // component beyond determining a size change.
    if (prevProps.chartSize !== this.props.chartSize) {
      this.onResize()
    }

    // re-position line if index changes
    if (prevProps.index !== this.props.index) {
      this.throttlePosLine()
    }

    // determine if focus line should be hidden or shown
    if (prevProps.showFocus !== this.props.showFocus) {
      this.toggleFocusLine(this.props.showFocus)
    }

    // determine data changes
    if (prevProps.datasets !== this.props.datasets) {
      // get new max and draw again
      this.getMax(this.drawChart)
    }
  }

  toggleFocusLine = enabled => {
    this.state.focusLine.style('display', enabled ? null : 'none')
  }

  reposLine = () => {
    const { focusLine, x, data } = this.state
 
    const d = data[this.props.index]
    // d can be undefined if some charts load faster
    // than others, causing this one to not have data
    // at this index
    if (d) {
      focusLine.attr(
        'transform',
        'translate(' + x(d.date) + ',' + this.state.margin.top + ')'
      )
    }
  }

  posTooltip = () => {
    const { datasets } = this.props
    const tooltip = d3.select(`.tooltip-${datasets[0].id}`)
    if (!tooltip.nodes()[0]) {
      return
    }
    const tooltipWidth = tooltip.nodes()[0].getBoundingClientRect().width
    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth

    // Moves the tooltip to the left of the cursor once the
    // right border of the tooltip box approaches the right
    // side of the browser
    const tooltipPos = ((d3.event.pageX + tooltipWidth) < (width - 40))
      ? 20 : (- 20 - tooltipWidth)

    tooltip
      .style('left', (d3.mouse(this.parentEl.current)[0] + tooltipPos) + 'px')
  }

  mouseout = () => this.props.onShowFocus(false)

  mouseover = () => {
    this.props.onShowFocus(true)
    this.posTooltip()
  }

  mousemove = () => {
    const { x, data } = this.state

    const x0 = x.invert(d3.mouse(this.parentEl.current)[0])
    const bisectDate = d3.bisector(function(d) {
      return d.date
    }).left
    const i = bisectDate(data, x0, 1)
    const d0 = data[i - 1]
    const d1 = data[i]
    if (!d1) {
      return
    }
    const d = x0 - d0.date > d1.date - x0 ? d1 : d0
    let index = i
    if (d === d0) {
      index = i - 1
    }

    if (this.props.index !== index) {
      this.props.indexChange(index)
      this.posTooltip()
    }
  }

  drawHoverLine() {
    const { svg, h, w } = this.state
    
    const focusLine = svg.append('g').style('display', 'none')

    focusLine
      .append('line')
      .attr('class', 'hover-line')
      .attr('y1', 0)
      .attr('y2', h - this.state.margin.top - this.state.margin.bottom)

    svg
      .append('rect')
      .attr('transform', 'translate(' + this.state.margin.left + ',' + this.state.margin.top + ')')
      .attr('class', 'line-overlay')
      .attr('width', w - this.state.margin.left - this.state.margin.right)
      .attr('height', h - this.state.margin.top - this.state.margin.bottom)
      .on('mouseover', this.mouseover)
      .on('mouseout', this.mouseout)
      .on('mousemove', this.mousemove)

    this.setState({ focusLine }, () => {
      this.toggleFocusLine(this.props.showFocus)
      this.reposLine()
    })
  }

  drawLine(chart) {
    const { svg, x, h, max } = this.state

    const data = this.formatData(chart.data)

    const y = d3
      .scaleLinear()
      .domain([0, max])
      .range([h - this.state.margin.bottom, this.state.margin.top])

    const line = d3
      .line()
      .defined(d => !isNaN(d.value))
      .x(d => x(d.date))
      .y(d => y(d.value))

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', chart.settings.color)
      .attr('class', 'line')
      .attr('stroke-width', strokeWidth)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('d', line)

    if (this.props.showArea) {
      svg.append("path")
        .datum(data)
        .attr("fill", chart.settings.color + '12') // Secret alpha code to get 7% opacity https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
        .attr("d", d3.area()
          .x(function(d) { return x(d.date) })
          .y0(y(0))
          .y1(function(d) { return y(d.value) })
        )
    }
  }

  formatData(dataInput) {
    const data = dataInput.map(point => {
      return { date: new Date(point[0] * 1000), value: point[1] }
    })

    return data
  }

  // To finish
  // stackDatasets(datasets) {
  //   datasets.map((dataset, index) => {
  //     if (index === 0) {
  //       dataset.data.forEach((row, i) => {
  //         if (!!datasets[1] && !!datasets[1].data[i]) {
  //           datasets[1].data[i][1] = datasets[1].data[i][1] + row[1];
  //         }
  //       })
  //     }
  //   })

  //   return datasets;
  // }

  getMax(onComplete) {
    const { datasets } = this.props;
    // const datasets = this.stackDatasets(this.props.datasets);

    const maxes = []

    datasets.forEach(dataset =>
      maxes.push(d3.max(this.formatData(dataset.data), d => d.value))
    )
    const max = d3.max(maxes) || 1

    this.setState({ max }, onComplete)
  }

  drawOtherCharts() {
    const { datasets } = this.props

    datasets.forEach(chart => {
      this.drawLine(chart)
    })

    this.drawHoverLine()
  }

  drawChart() {
    const { max } = this.state
    const { datasets } = this.props

    d3.select(this.parentEl.current)
      .selectAll(`svg`)
      .remove()

    const dataInput = datasets[0].data
    const data = this.formatData(dataInput)

    const w = this.state.width
    const h = this.state.height

    const x = d3
      .scaleTime()
      .domain(d3.extent(data, d => d.date))
      .range([this.state.margin.left, w - this.state.margin.right])

    const y = d3
      .scaleLinear()
      .domain([0, max])
      .range([h - this.state.margin.bottom, this.state.margin.top])
    
    const xAxis = g =>
      g
        .attr('transform', `translate(0,${h - this.state.margin.bottom})`)
        .attr('font-weight', `400`)
        .call(
          d3
            .axisBottom(x)
            .ticks(6)
            .tickSize(-h + this.state.margin.bottom + this.state.margin.top)
            .tickSizeOuter(0)
        )

    const tickStep = `${Number(max / 3).toFixed(2)}`
    const ticks = [tickStep, tickStep * 2, max]

    const yAxis = g =>
      g
        .attr('transform', `translate(${this.state.margin.left},0)`)
        .call(
          d3
            .axisLeft(y)
            .tickValues(ticks)
            .tickFormat(val => numeral(val).format('0.0a'))
            .tickSize(-w + 69)
            .tickSizeOuter(0)
        )
        .call(g => g.select(".domain").remove())

    const svg = d3
      .select(this.parentEl.current)
      .append('svg')
      .attr('height', h)
      .attr('width', w)

    if (this.props.showAxis) {
      svg
        .append('g')
        .attr('class', 'ticks')
        .style('font-size', '11px')
        .call(xAxis)
        
      svg
        .append('g')
        .attr('class', 'ticks')
        .style('font-size', '11px')
        .call(yAxis)
    }

    this.setState({ svg, x, data, w, h, max }, this.drawOtherCharts)
  }

  render() {
    // we use the ref to find the element inside d3
    // the chart className is only used for styling
    return <div ref={this.parentEl} className="chart" />
  }
}

Line.defaultProps = {
  showAxis: true,
  showArea: false
}

export default Line

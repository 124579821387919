import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import Button from './button'

const modalRoot = document.getElementById('modals')

class Modal extends Component {
  onClose = e => {
    e.stopPropagation()
    this.props.onClose()
  }

  render() {
    if (!this.props.show) {
      return null
    }

    let contentStyle = {}
    let actionStyle = {}

    if (this.props.width) {
      contentStyle.width = this.props.width
    } else if (this.props.maxWidth) {
      contentStyle.maxWidth = this.props.maxWidth
    }

    if (this.props.noPadding) {
      contentStyle.padding = 0
      actionStyle.margin = 20
    }

    const mod = (
      <div className="modalWrapper" onClick={this.onClose}>
        <div className="modal">
          <div
            className="modalContent"
            style={contentStyle}
            onClick={e => e.stopPropagation()}
          >
            {this.props.title && <h2>{this.props.title}</h2>}
            {this.props.children}
            <div className="modalActions" style={actionStyle}>
              <Button  onClick={this.props.onClose}>
                {this.props.closeLabel || 'Close'}
              </Button>
              {this.props.actions}
            </div>
          </div>
        </div>
      </div>
    )

    return ReactDOM.createPortal(mod, modalRoot)
  }
}

export default Modal

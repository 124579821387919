import React from 'react'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns-tz/format'
import isValid from 'date-fns/isValid'
import setMinutes from 'date-fns/setMinutes'
import setHours from 'date-fns/setHours'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'

import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import Overlay from '../../../shared/overlay'
import Time from './time'
import css from './timerange.module.css'
import Timesets from './timesets'

export const formatUTC = time =>
  format(time, "yyyy-MM-dd'T'HH:mm:ss", {
    timeZone: 'UTC'
  })

// updateDate changes the date portion of an original timestamp
// and leaves the time intact. We need this because our timerange picker
// would reset the time otherwise
const updateDate = (original, date) => {
  if (!original || !isValid(original)) {
    return date
  }

  const hours = getHours(original)
  const minutes = getMinutes(original)

  return setHours(setMinutes(date, minutes), hours)
}

const Timerange = ({ query, setQuery }) => {
  const from = parseISO(query.from) || new Date()
  const to = parseISO(query.to) || new Date()

  const setTimerange = ({ selection }) => {
    if (selection.startDate && selection.endDate) {
      setQuery({
        ...query,
        from: formatUTC(updateDate(from, selection.startDate)),
        to: formatUTC(updateDate(to, selection.endDate))
      })
    }
  }

  const setTimestamp = (key, value) => {
    setQuery({ ...query, [key]: formatUTC(value) })
  }

  const setBoth = (from, to) => {
    setQuery({
      ...query,
      from: formatUTC(from),
      to: formatUTC(to)
    })
  }

  const selectionRange = {
    startDate: from,
    endDate: to,
    key: 'selection'
  }

  const rangePicker = value => (
    <Overlay
      trigger={
        <input
          readOnly
          value={format(value, 'yyyy-MM-dd')}
          className={css.date}
        />
      }
      position="bottom-center"
    >
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={setTimerange}
        color="#4a3e60"
        rangeColors={['#4a3e60']}
        showDateDisplay={false}
        moveRangeOnFirstSelection={false}
        inputRanges={[]}
      />
    </Overlay>
  )

  return (
    <div className={css.timerange}>
      <label>From</label>
      {rangePicker(from)}
      <Time time={from} setTime={t => setTimestamp('from', t)} />
      <label>to</label>
      {rangePicker(to)}
      <Time time={to} setTime={t => setTimestamp('to', t)} allSeconds />
      <label>UTC</label>
      <Timesets onUpdate={setBoth} />
    </div>
  )
}

export default Timerange

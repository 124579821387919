import React, { Component } from 'react'
import Button from '../../shared/button'
import user from '../../data/user'
import toast from '../../data/toast'

class VerifyBanner extends Component {
  state = {
    isResending: false,
    hasResent: false
  }

  onResend = () => {
    this.setState({ isResending: true })

    user.ResendValidationEmail(res => {
      this.setState({ isResending: false, hasResent: true })
      toast.open({ msg: 'Verification email sent!', type: 'success' })
    })
  }

  render() {
    return (
      <div className="headerBanner info">
        Please verify your email address by clicking the link in your welcome
        email.{' '}
        <Button
          small
          loading={this.state.isResending}
          disabled={this.state.hasResent}
          onClick={this.onResend}
        >
          Resend Email
        </Button>{' '}
        or{' '}
        <Button small to="/settings/account">
          Change Email Address
        </Button>
      </div>
    )
  }
}

export default VerifyBanner

import React from 'react'
import update from 'immutability-helper'

import Button from '../../../shared/button'
import Item from './item'

import css from './querying.module.css'

const Items = ({ fields, items, setItems }) => {
  const onAdd = () => {
    setItems([...items, { type: 'number', aggregation: 'count' }])
  }

  return (
    <div className={css.container}>
      <ul className={css.rows + ' ' + css.items}>
        {items.map((item, index) => (
          <Item
            index={index}
            fields={fields}
            item={item}
            setItem={item =>
              setItems(update(items, { [index]: { $set: item } }))
            }
            onDelete={() => setItems(update(items, { $splice: [[index, 1]] }))}
          />
        ))}
      </ul>
      <Button onClick={onAdd} small style={{ marginLeft: 35 }}>
        Add Item
      </Button>
    </div>
  )
}

export default Items

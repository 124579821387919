import { Component } from 'react'
import { withRouter } from 'react-router'

const trackView = page => {
  window.gtag && window.gtag('config', 'UA-149272870-1', { page_path: page })
}

class Track extends Component {
  componentDidUpdate(prevProps) {
    trackView(this.props.location.pathname)
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(Track)

import React, { Component } from 'react'
import Logotype from '../../../images/logotype'

class AuthHeader extends Component {
  render() {
    return (
      <>
        <header>
          <Logotype />
        </header>
        <div className="authBackdrop" />
      </>
    )
  }
}

export default AuthHeader

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import User from '../../data/user'
import { ValidateEmail } from '../../data/validators'

import './auth.css'
import toast from '../../data/toast'
import Button from '../../shared/button'
import AuthHeader from './header'

class Forgot extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      loading: false,
      error: '',
      success: false
    }
  }

  onSubmit = e => {
    e.preventDefault()

    const data = {
      email: this.state.email
    }

    if (!this.validateData(data)) {
      return
    }

    this.setState({ loading: true })

    User.RequestReset(data, res => {
      this.setState({ loading: false })
      if (res.success) {
        this.setState({ success: true })
        toast.open({ msg: 'Email sent successfuly!', type: 'success' })
      } else {
        this.setState({ error: res.error })
      }
    })
  }

  validateData(data) {
    if (!ValidateEmail(data.email)) {
      this.setState({ error: 'Please enter a valid email address.' })
      return false
    }
    return true
  }

  render() {
    return (
      <div className="authWrapper">
        <AuthHeader />
        <h1 className="authTitle">Forgot Password</h1>
        <div className="authBox">
          <form onSubmit={this.onSubmit} className="authForm">
            <p className="authFormHeader">
              Enter your email address below and we'll email you a link to reset
              your password.
            </p>
            <div className="authFormSection">
              <label>Email</label>
              <input
                type="email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                placeholder="name@example.com"
                autoFocus
              />
            </div>
            <div className="authFormError">{this.state.error}</div>
            {this.state.success ? (
              <Button to="/login" right>
                Back to Login
              </Button>
            ) : (
              <Button primary right loading={this.state.loading}>
                Send password reset email
              </Button>
            )}
          </form>
        </div>
        <Link to="/login" className="authSubLink">
          Back to Login
        </Link>
      </div>
    )
  }
}

export default Forgot

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

class Button extends Component {
  render() {
    let classes = classNames({
      btn: true,
      'btn--primary': this.props.primary,
      'btn--secondary': this.props.secondary,
      'btn--loading': this.props.loading,
      'btn--disabled': this.props.disabled,
      'btn--danger': this.props.danger,
      'btn--large': this.props.large,
      'btn--small': this.props.small,
      'btn--ellipsis': this.props.ellipsis,
      'btn--light': this.props.light,
      'btn--clear': this.props.clear,
      'btn--play': this.props.play && !this.props.loading
    })

    if (this.props.className) {
      classes += ' ' + this.props.className
    }

    if (this.props.href) {
      return (
        <a
          href={this.props.href}
          target="_blank"
          rel="noopener noreferrer"
          className={classes}
          disabled={this.props.disabled}
          style={this.props.style}
        >
          {this.props.children}
        </a>
      )
    }

    if (!this.props.disabled && this.props.to) {
      return (
        <Link
          to={this.props.to}
          className={classes}
          disabled={this.props.disabled}
          style={this.props.style}
        >
          {this.props.children}
        </Link>
      )
    }

    return (
      <button
        className={classes}
        onClick={
          this.props.loading || this.props.disabled
            ? () => {}
            : this.props.onClick
        }
        disabled={this.props.disabled}
        style={this.props.style}
      >
        {this.props.children}
      </button>
    )
  }
}

export default Button

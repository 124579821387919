import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Subheader from '../../../../layout/header/subheader'
import Button from '../../../../shared/button'
import Events from '../../../../data/models/events'

class Create extends Component {
  state = {
    name: '',
    isSaving: false,
    error: ''
  }

  onSave = () => {
    this.setState({ isSaving: true })

    const data = {
      id: this.state.name
    }

    Events.Create(data, res => {
      if (res && res.success) {
        this.props.history.replace('/')
      } else {
        this.setState({
          isSaving: false,
          error: res?.error || 'An unknown error occured'
        })
      }
    })
  }

  render() {
    const { name, isSaving } = this.state
    return (
      <>
        <Helmet>
          <title>Create Event</title>
        </Helmet>
        <Subheader
          parent={{
            title: 'Events',
            path: '/'
          }}
          title="Create Event"
        >
          <div className="actions">
            <Button to="/">Cancel</Button>
          </div>
        </Subheader>
        <div className="wr">
          <div className="box" style={{ marginBottom: 30, maxWidth: 500 }}>
            <h3 style={{ marginTop: 0 }}>Name</h3>
            <input
              type="text"
              className="formInput"
              placeholder="myEvent"
              value={name}
              pattern="[^a-zA-Z0-9_\-.]"
              onChange={e => this.setState({ name: e.target.value })}
            />
            <p>
              Your event-name can contain upper and lower-case letters, numbers,
              underscores, hyphens and dots ([^a-zA-Z0-9_\-.]). You can not
              rename the event after creating it.
            </p>
            {this.state.error && (
              <div className="formError" style={{ marginTop: 15 }}>
                {this.state.error}
              </div>
            )}
            <Button primary onClick={this.onSave} loading={isSaving}>
              Create Event
            </Button>
          </div>
        </div>
      </>
    )
  }
}

export default Create

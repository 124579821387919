import React, { Component } from 'react'
import update from 'immutability-helper'
import emitter from 'tiny-emitter/instance'
import Fuse from 'fuse.js'
import Helmet from 'react-helmet'

import eventsData from '../../../data/models/events'
import alert from '../../../data/alert'
import Subheader from '../../../layout/header/subheader'
import Loading from '../../../shared/loading'

import toast from '../../../data/toast'
import Item from './item'

import star from '../../../../images/star.svg'
import EmptyState from '../../../shared/empty'
import Button from '../../../shared/button'
import Integrations from '../../docs/content/integrations'

const fuseOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.4,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['id']
}

class Events extends Component {
  state = {
    data: [],
    isLoading: false,
    filter: ''
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.getData()
    emitter.on('event.created', this.getData)
  }

  componentWillUnmount() {
    emitter.off('event.created', this.getData)
  }

  getData = () => {
    eventsData.GetList(res => {
      this.setState({
        data: res,
        fuse: new Fuse(res, fuseOptions),
        isLoading: false
      })
    })
  }

  onFavorite = (itemId, isFav) => {
    const data = this.state.data
    const index = data.findIndex(item => item.id === itemId)

    const newData = update(data, {
      [index]: {
        isFavorite: { $set: isFav }
      }
    })

    this.setState({
      data: newData,
      fuse: new Fuse(newData, fuseOptions)
    })

    eventsData.SetFavorite(itemId, isFav, () => {
      toast.open({ msg: isFav ? 'Favorite added' : 'Favorite removed' })
    })
  }

  onRemove = (itemId, name) => {
    alert.confirm({
      title: 'Delete event',
      text: (
        <span>
          This will delete the <strong>{name}</strong> event and all related
          data. This action cannot be undone.
        </span>
      ),
      confirmText: 'Confirm',
      onConfirm: done => {
        // send delete request to API
        eventsData.Delete(itemId, res => {
          toast.open({ msg: 'Event deleted', type: 'success' })
        })

        // find index of item to remove locally
        const index = this.state.data.findIndex(event => event.id === itemId)
        const newData = this.state.data
        newData.splice(index, 1)

        // add artifical delay for better feel
        setTimeout(() => {
          // remove item locally and close modal
          this.setState({ data: newData })
          done()
        }, 300)
      }
    })
  }

  onFilter = val => {
    this.setState({
      filter: val
    })
  }

  render() {
    let dataFiltered = []
    const { data, filter, fuse } = this.state

    // filter with fuse if necessary
    if (filter !== '') {
      dataFiltered = fuse ? fuse.search(filter) : []
    } else {
      // change the data because we need to
      // follow the same structure as fuse and fuse
      // incldudes an array of matches so we can highlight them
      dataFiltered = data.map(item => ({
        item: item,
        matches: []
      }))
    }

    // separate favs from normal ones
    let favs = []
    const normal = dataFiltered.filter(event => {
      if (event.item.isFavorite) {
        favs.push(event)
        return false
      }
      return true
    })

    return (
      <React.Fragment>
        <Helmet>
          <title>Events</title>
        </Helmet>
        <Subheader
          title="Events"
          filter={this.state.filter}
          onFilter={this.onFilter}
          actions={<Button to="/events/new">Create Event</Button>}
        />
        <div className="wr">
          {this.state.isLoading ? (
            <div className="list">
              <Loading />
            </div>
          ) : favs.length || normal.length ? (
            <React.Fragment>
              {favs && favs.length ? (
                <React.Fragment>
                  <h3 className="listTitle">
                    <img src={star} alt="" />
                    Favorites
                  </h3>
                  <div className="list withTitle">
                    {favs.map(event => (
                      <Item
                        key={event.item.id}
                        item={event.item}
                        matches={event.matches}
                        onRemove={this.onRemove}
                        onFavorite={this.onFavorite}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ) : null}
              <h3 className="listTitle">All Others</h3>
              {normal.length !== 0 && (
                <div className="list withTitle">
                  {normal.map(event => (
                    <Item
                      key={event.item.id}
                      item={event.item}
                      matches={event.matches}
                      onRemove={this.onRemove}
                      onFavorite={this.onFavorite}
                    />
                  ))}
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="list">
              <EmptyState
                title={filter ? 'Nothing found 🕵🏻‍' : 'No events here yet 🕵🏻‍'}
                description={
                  !filter && (
                    <>
                      <span style={{ display: 'block', marginBottom: 20 }}>
                        A new event gets created when you send data through our
                        API.
                      </span>{' '}
                      <Integrations />
                    </>
                  )
                }
              />
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default Events

import React, { Component } from 'react'

import './valueprops.css'

import graphic from '../../../images/signup-graphic.png'

class ValueProps extends Component {
  render() {
    return (
      <div className="valueProps">
        <div className="valueGraphic">
          <img src={graphic} alt="Demo Graphic" />
        </div>
        <div className="freeTier">
          <span>Get started for free with...</span>
          <ul>
            <li>
              <strong>1,000,000</strong> Events
            </li>
            <li>
              <strong>500</strong> Queries
            </li>
            <li>For 30 days</li>
            <li>No credit card required</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default ValueProps

import React, { Component } from 'react'
import queryString from 'query-string'

import Loading from '../../shared/loading'

import './auth.css'
import Button from '../../shared/button'
import Changelog from '../../data/models/changelog'
import alert from '../../data/alert'
import AuthHeader from './header'

class Unsubscribe extends Component {
  state = {
    isLoading: true
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search)

    this.sendRequest(query.email)
  }

  sendRequest = email => {
    Changelog.Unsubscribe(email, res => {
      if (res.error) {
        alert.alert({
          title: 'An error occurred',
          text: 'Error: ' + res.error
        })
      }
      this.setState({
        isLoading: false
      })
    })
  }

  render() {
    return (
      <div className="authWrapper">
        <AuthHeader />
        <h1 className="authTitle">Unsubscribe</h1>
        <div className="authBox">
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <div className="authForm">
              <div>
                You've been unsubscribed from regular product update emails.
              </div>
              <Button to="/" style={{ width: '100%', marginTop: 20 }}>
                View Dashboard
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Unsubscribe

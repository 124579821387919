import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Integrations extends Component {
  render() {
    return (
      <ul className="boxPicker">
        <li>
          <Link to="/docs/send-events/http-post">HTTP Post</Link>
        </li>
        <li>
          <Link to="/docs/send-events/nodejs">Node.js</Link>
        </li>
        <li>
          <Link to="/docs/send-events/golang">Go</Link>
        </li>
      </ul>
    )
  }
}

export default Integrations

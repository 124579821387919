import React from 'react'

const Logotype = ({ color = 'currentColor' }) => {
  return (
    <svg
      viewBox="0 0 206 51"
      width="206"
      height="51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.7 26.6c0 .8 0 1.6-.2 2.3H79.1a6 6 0 0 0 1.8 4c1 1 2.4 1.5 4 1.5a5 5 0 0 0 4.7-2.9h6.5A11 11 0 0 1 85 39.4c-2.3 0-4.4-.5-6.2-1.5a11 11 0 0 1-4.3-4.4c-1-1.8-1.5-4-1.5-6.4 0-2.5.5-4.6 1.5-6.5 1-1.9 2.4-3.3 4.2-4.3 1.8-1 3.9-1.5 6.3-1.5 2.2 0 4.2.5 6 1.5a10 10 0 0 1 4.2 4.1c1 1.8 1.5 3.9 1.5 6.2Zm-6.2-1.7c0-1.6-.6-2.8-1.7-3.7-1.1-1-2.4-1.5-4-1.5-1.5 0-2.8.5-3.8 1.4a6 6 0 0 0-1.9 3.8h11.4ZM111 33.5l6-18.3h6.4L114.6 39h-7.3l-8.7-23.8h6.4l6 18.3ZM149 26.6c0 .8 0 1.6-.2 2.3h-17.4a6 6 0 0 0 1.8 4c1 1 2.4 1.5 4 1.5a5 5 0 0 0 4.7-2.9h6.5a11 11 0 0 1-11.2 7.9c-2.3 0-4.3-.5-6.2-1.5a11 11 0 0 1-4.2-4.4c-1-1.8-1.5-4-1.5-6.4 0-2.5.5-4.6 1.5-6.5 1-1.9 2.4-3.3 4.2-4.3 1.8-1 3.9-1.5 6.2-1.5 2.3 0 4.3.5 6.1 1.5a10 10 0 0 1 4.2 4.1c1 1.8 1.5 3.9 1.5 6.2Zm-6.3-1.7c0-1.6-.5-2.8-1.6-3.7-1.1-1-2.5-1.5-4-1.5s-2.8.5-3.8 1.4a6 6 0 0 0-1.9 3.8h11.3ZM166.6 14.8c2.8 0 5.1 1 6.8 2.7 1.8 1.8 2.7 4.3 2.7 7.5v14h-6V25.8c0-1.9-.5-3.3-1.5-4.3a5 5 0 0 0-3.8-1.5c-1.7 0-3 .5-4 1.5s-1.4 2.4-1.4 4.3V39h-6V15.2h6v3c.8-1 1.8-1.9 3-2.5 1.3-.6 2.7-.9 4.2-.9ZM180.2 27c0-2.3.5-4.4 1.5-6.3 1-1.8 2.4-3.3 4-4.3 1.7-1 3.6-1.6 5.6-1.6a9.7 9.7 0 0 1 7.7 3.5v-3.1h6v35.2h-6V35.7a9.9 9.9 0 0 1-7.8 3.6 10.4 10.4 0 0 1-9.5-5.9c-1-1.9-1.5-4-1.5-6.4Zm18.8 0c0-1.4-.3-2.6-1-3.7a6.2 6.2 0 0 0-10.7 0c-.6 1-.9 2.3-.9 3.7 0 1.5.3 2.8.9 3.9.6 1 1.4 1.8 2.3 2.4a6.2 6.2 0 0 0 8.5-2.4c.6-1 .9-2.3.9-3.8Z"
        fill="#1E1F31"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m45.5 1 13.9 23a2 2 0 0 1 0 2l-14 23c-.3.6-1 1-1.6 1H16a2 2 0 0 1-1.8-1L.2 26a2 2 0 0 1 0-2l14-23c.4-.6 1-1 1.8-1h27.8a2 2 0 0 1 1.7 1ZM31 27 19.9 45.7h22.6l11.2-18.6H31Zm-4.3-11.4 4.3 7.2h22.6l-4.4-7.2H26.8ZM6 22.9h20L16 6.3 6 22.9Zm10 20.8 10-16.6H6l10 16.6Zm8.2-32.3h22.5l-4.2-7.1H19.9l4.3 7Z"
        fill="#1E1F31"
      />
    </svg>
  )
}

export default Logotype

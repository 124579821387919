import React, { Component } from 'react'

import Select from '../../shared/select/select'
import user from '../../data/user'

import './orgs.css'
import Org from '../../data/models/org'

class OrgSwitcher extends Component {
  state = {
    orgs: [],
    isLoading: true
  }

  componentDidMount() {
    Org.GetList(res => {
      this.setState({
        orgs: res,
        isLoading: false
      })
    })
  }

  onSelectOrg = val => {
    user.SetOrg(val)
    // just do a hard-reload so we don't have to deal
    // with the possibility of outdated data anywhere
    window.location.pathname = '/'
  }

  render() {
    const activeId = user.GetOrg()

    const options = this.state.orgs.map(org => ({
      label: org.name,
      value: org.id
    }))

    return (
      <div className="orgSwitcher">
        <span>Team</span>
        <Select
          options={options}
          value={activeId}
          onChange={this.onSelectOrg}
          noBorder
          width={240}
          menuStyle={{
            width: 220,
            marginLeft: 10
          }}
        />
      </div>
    )
  }
}

export default OrgSwitcher

import React from 'react'

import css from './import.module.css'

const Mapping = ({ mapping, setMapping, fields }) => {
  let tsKey = Object.keys(mapping).find(k => mapping[k] === '__evenqts')
  let partitionKey = Object.keys(mapping).find(k => mapping[k] === '__evenqpk')

  const update = (key, value) => {
    setMapping({ ...mapping, [key]: value })
  }

  return (
    <div>
      <p>
        Below you can rename any CSV header names. To skip a column, leave the
        "Event Key" field empty. You can designate one field each for the event
        timestamp and for the partition key.
      </p>
      <ul className={css.mapping}>
        <li>
          <span>CSV Key --></span>
          <span>Event Key</span>
        </li>
        {Object.keys(mapping).map(key => (
          <li key={key}>
            <span>{key}</span>
            <input
              type="text"
              value={mapping[key]}
              onChange={e => update(key, e.target.value)}
              disabled={key === tsKey || key === partitionKey}
              placeholder="not imported"
            />
            <label>
              <input
                type="checkbox"
                checked={key === tsKey}
                onChange={e =>
                  e.target.checked ? update(key, '__evenqts') : update(key, key)
                }
                disabled={
                  key === partitionKey || (tsKey !== undefined && key !== tsKey)
                }
              />
              Timestamp
            </label>
            <label>
              <input
                type="checkbox"
                checked={key === partitionKey}
                onChange={e =>
                  e.target.checked ? update(key, '__evenqpk') : update(key, key)
                }
                disabled={
                  key === tsKey ||
                  (partitionKey !== undefined && key !== partitionKey)
                }
              />
              Partition Key
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Mapping

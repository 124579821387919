import emitter from 'tiny-emitter/instance'

import API from './api'
import alert from './alert'

import PusherClient from './pusher'
import toast from './toast'
import Org from './models/org'

// This class is for the currently logged-in user
class User {
  constructor() {
    this.user = {}
    this.orgChannel = {}
    this.pusherClient = null
  }

  connectOrgChannel(orgId) {
    this.pusherClient = PusherClient.Get(`/orgs/${orgId}/connect`)
    this.orgChannel = this.pusherClient.subscribe(`presence-org=${orgId}`)
    this.orgChannel.bind('event.created', data => {
      toast.open({
        msg: 'New event: ' + data.name,
        timeout: 10000,
        link: {
          title: 'View',
          url: '/queries/' + data.id
        }
      })
      emitter.emit('event.created', data)
    })
    this.orgChannel.bind('user.verified', data => {
      console.log('user.verified', data)
      this.user.isVerified = true
      emitter.emit('user.verified', data)
      toast.open({ msg: 'Email Verified', type: 'success' })
    })
  }

  updateUsage = () => {
    Org.Usage(res => {
      this.usage = res
      emitter.emit('usage.updated', res)
    })
  }

  GetUsage() {
    return this.usage
  }

  Get() {
    return this.user
  }

  // we save our default org ID to localstorage
  SetOrg(orgId) {
    window.localStorage.setItem('orgId', orgId)
  }

  // we just default to the first one right now
  // later on we can let the user pick which org
  // they want to view the dashboard as
  GetOrg() {
    // check for value in local storage
    const orgId = window.localStorage.getItem('orgId')
    const userOrgs = this.user.orgIds || []

    // if we have a valid orgId from localStorage
    // and we have access to that org, return that org
    // otherwise return the first of ours
    if (orgId && userOrgs.indexOf(orgId) !== -1) {
      return orgId
    }

    return userOrgs ? userOrgs[0] : 0
  }

  IsLoggedIn() {
    return this.user.id && this.user.id !== 0
  }

  Refresh = cb => {
    API.Get({
      path: '/me',
      success: data => {
        if (data.id) {
          this.user = data
        }
        cb && cb(data)
      }
    })
  }

  CheckAuth = cb => {
    API.Get({
      path: '/me',
      success: data => {
        if (data.id) {
          this.user = data

          // connect the user to receive realtime
          // updates on org level
          this.connectOrgChannel(this.GetOrg())
          this.updateUsage()
        }
        cb && cb(data)
      },
      error: () => {
        alert.confirm({
          title: 'Connection error',
          text: 'Could not reach backend server, please try again. This could be due to a problem with your internet connection.',
          confirmText: 'Try Again',
          onConfirm: () => {
            window.location.reload()
          }
        })
      }
    })
  }

  Login = (email, password, cb) => {
    API.Post({
      path: '/auth/login',
      data: {
        email: email,
        password: password
      },
      success: cb,
      error: cb
    })
  }

  Logout = cb => {
    API.Post({
      path: '/auth/logout',
      success: res => {
        cb && cb(res)
      },
      error: () => cb && cb()
    })
  }

  Signup = (data, cb) => {
    API.Post({
      path: '/signup',
      data: data,
      success: res => {
        if (res.id) {
          this.user = res
        }
        cb(res)
      },
      error: cb
    })
  }

  RequestReset = (data, cb) => {
    API.Post({
      path: '/auth/reset.password',
      data: data,
      success: cb,
      error: cb
    })
  }

  ResetPassword = (data, cb) => {
    API.Patch({
      path: '/auth/reset.password',
      data: data,
      success: res => {
        cb(res)
      },
      error: cb
    })
  }

  ValidateVerificationToken = (token, cb) => {
    API.Post({
      path: '/auth/verifyemail',
      data: {
        token: token
      },
      success: cb,
      error: cb
    })
  }

  ResendValidationEmail = cb => {
    API.Post({
      path: '/auth/resendverify',
      success: cb,
      error: cb
    })
  }

  UpdateMeta = (data, cb) => {
    API.Patch({
      path: '/me',
      data: data,
      success: cb,
      error: cb
    })
  }

  UpdatePassword = (data, cb) => {
    API.Patch({
      path: '/me/password',
      data: data,
      success: cb,
      error: cb
    })
  }

  SubscribeChangelog = (sub, cb) => {
    API.Post({
      path: '/me/subscriptions/changelog',
      data: {
        isSubscribed: sub
      },
      success: cb,
      error: cb
    })
  }

  // Delete user account
  Delete = (data, cb) => {
    API.Delete({
      path: '/me',
      data: data,
      success: cb,
      error: cb
    })
  }
}

// export the user as instance so we always refer to this
// instance when importing it anywhere
export default new User()

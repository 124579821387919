import emitter from 'tiny-emitter/instance'

// export this helpful const to use instead
// of strings every time
export const timerEvent = 'timekeeper.new'

// timeKeeper runs throughout the entire
// app lifecycle and emits an event when
// we have passed a minute, meaning there
// is new data to be fetched

export const getSecondsBeforeRefresh = () => {
  const now = new Date()

  const seconds = now.getSeconds()
  if (seconds > 10) {
    return 70 - seconds;
  } else {
    return 10 - seconds
  }
}

export const timeKeeper = function() {
  const checkTime = function() {
    const now = new Date()
    const sec = now.getSeconds()

    // at every 10 seconds past the minute
    // we emit the data update event. This
    // give the backend 10 seconds to process
    // new events. We need to keep an eye on that.
    if (sec === 10) {
      emitter.emit(timerEvent)
    }
  }

  setInterval(checkTime, 1000)
}

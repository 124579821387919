import React, { Component } from 'react'
import format from 'date-fns/format'
// import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import setSeconds from 'date-fns/setSeconds'

import classNames from 'classnames'

import css from './timerange.module.css'

const parseStr = str => {
  const regex = /^([0-9]{1,2}):([0-9]{1,2})$/
  const matches = regex.exec(str)

  if (!matches || matches.length !== 3) {
    return false
  }

  const hours = Number(matches[1])
  const minutes = Number(matches[2])

  if (isNaN(hours) || hours > 23 || hours < 0) {
    return false
  }

  if (isNaN(minutes) || minutes > 59 || minutes < 0) {
    return false
  }

  return {
    hours,
    minutes
  }
}

class Time extends Component {
  state = {
    value: '',
    isValid: true,
    isFocused: false
  }

  componentDidMount() {
    this.setState({ value: format(this.props.time, 'HH:mm') })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.time !== this.props.time && !this.state.isFocused) {
      this.setState({ value: format(this.props.time, 'HH:mm') })
    }
  }

  onChange = value => {
    this.setState({ value })
    const parsed = parseStr(value)

    if (parsed === false) {
      this.setState({ isValue: false })
    } else {
      this.setState({ isValue: true })

      this.props.setTime(
        setSeconds(
          setMinutes(setHours(this.props.time, parsed.hours), parsed.minutes),
          this.props.allSeconds ? 59 : 0
        )
      )
    }
  }

  render() {
    return (
      <input
        type="text"
        value={this.state.value}
        onChange={e => this.onChange(e.target.value)}
        className={classNames(css.time, {
          [css.invalid]: !this.state.isValid
        })}
        placeholder="HH:MM"
        onFocus={() => this.setState({ isFocused: true })}
        onBlur={() => this.setState({ isFocused: false })}
      />
    )
  }
}

export default Time

import React, { Component } from 'react'
import queryString from 'query-string'

import User from '../../data/user'

import './auth.css'

import Loading from '../../shared/loading'
import Button from '../../shared/button'
import AuthHeader from './header'

const noTokenError =
  'The URL you visited is missing a token. Please copy the URL from the email we sent you.'

class Welcome extends Component {
  constructor(props) {
    super(props)

    const query = queryString.parse(this.props.location.search)

    this.state = {
      loading: true,
      token: query.t,
      error: query.t ? '' : noTokenError,
      success: false
    }
  }

  componentDidMount() {
    if (this.state.token) {
      this.validateToken()
    }
  }

  onConfirm = () => {
    window.location.href = '/'
  }

  validateToken = () => {
    User.ValidateVerificationToken(this.state.token, res => {
      if (res.error) {
        this.setState({
          loading: false,
          error: res.error
        })
      } else {
        this.setState({ success: true, loading: false })
      }
    })
  }

  render() {
    return (
      <div className="authWrapper">
        <AuthHeader />
        <h1 className="authTitle">Verifying Email</h1>
        <div className="authBox">
          {this.state.error ? (
            <div className="authFormError">{this.state.error}</div>
          ) : (
            this.state.isLoading && <Loading />
          )}
          {this.state.success && (
            <div className="authForm">
              <div>
                Success! Your email address has been verified and you can now
                publish pages.
              </div>
              <Button
                onClick={this.onConfirm}
                primary
                large
                style={{ width: '100%', marginTop: 20 }}
              >
                View Dashboard
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Welcome

import React, { Component } from 'react'
import queryString from 'query-string'

import User from '../../data/user'
import Button from '../../shared/button'

import './auth.css'
import AuthHeader from './header'

class Join extends Component {
  constructor(props) {
    super(props)

    const query = queryString.parse(this.props.location.search)

    this.state = {
      // form data
      token: query.token,
      email: query.email,
      password: '',
      acceptTerms: false,
      // UI state
      loading: false,
      error: ''
    }
  }

  onSubmit = e => {
    e.preventDefault()

    const data = {
      password: this.state.password,
      token: this.state.token
    }

    if (!this.validateData(data)) {
      return
    }

    this.setState({ loading: true })

    User.ResetPassword(data, res => {
      this.setState({ loading: false })
      if (res.success) {
        window.location.pathname = '/'
      } else {
        this.setState({ error: res.error })
      }
    })
  }

  validateData(data) {
    if (data.password.length < 6) {
      this.setState({
        error: 'Your password has to be at least 6 characters long.'
      })
      return false
    }

    if (!this.state.acceptTerms) {
      this.setState({
        error:
          'You need to agree with our Privacy Policy and Terms of Service to sign up.'
      })
      return false
    }

    return true
  }

  render() {
    return (
      <div className="authWrapper">
        <AuthHeader />
        <h1 className="authTitle">Accept Invitation</h1>
        <div className="authBox">
          <form onSubmit={this.onSubmit} className="authForm">
            <div className="authFormSection">
              <label>Email</label>
              <input type="email" value={this.state.email} disabled />
            </div>
            <div className="authFormSection">
              <label>Password</label>
              <input
                type="password"
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
              />
            </div>
            <div
              className="authFormNotice"
              onClick={() =>
                this.setState({ acceptTerms: !this.state.acceptTerms })
              }
            >
              <span
                aria-checked={this.state.acceptTerms}
                aria-labelledby="label-terms"
                id="terms"
                role="checkbox"
                tabIndex="0"
                aria-label=""
                placeholder=""
                className={'ckbox' + (this.state.acceptTerms ? ' checked' : '')}
              />
              <label htmlFor="terms" id="label-terms">
                I agree to the Evenq
                <br />
                <a
                  href="https://evenq.io/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                &amp;{' '}
                <a
                  href="https://evenq.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </label>
            </div>
            <div className="authFormError">{this.state.error}</div>
            <Button
              primary
              large
              loading={this.state.loading}
              style={{ margin: '0 auto', marginTop: 25, display: 'flex' }}
            >
              Create my account
            </Button>
          </form>
        </div>
      </div>
    )
  }
}

export default Join

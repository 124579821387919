import React, { Component } from 'react'
import Helmet from 'react-helmet'
import numeral from 'numeral'

import eventData from '../../../data/models/events'
import Loading from '../../../shared/loading'
import Subheader from '../../../layout/header/subheader'

import css from './event.module.css'
import Button from '../../../shared/button'

class Event extends Component {
  state = {
    id: this.props.match.params.id,
    data: {},
    isLoading: true,
    isQuerying: false,
    isDeleting: false,
    error: false
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    eventData.Get(this.state.id, res => {
      this.setState({
        data: res,
        isLoading: false
      })
    })
  }

  onDelete = () => {
    const ok = window.confirm(
      "Are you sure you want to delete this event and all it's data?"
    )

    if (ok) {
      this.setState({ isDeleting: true })

      eventData.Delete(this.state.id, res => {
        this.props.history.push('/')
      })
    }
  }

  render() {
    const { data, isLoading } = this.state

    if (isLoading) {
      return <Loading />
    }

    return (
      <>
        <Helmet>
          <title>{data.id}</title>
        </Helmet>
        <Subheader
          parent={{
            title: 'Events',
            path: '/'
          }}
          title={isLoading ? 'Loading...' : data.id}
        >
          <div className="actions">
            <span style={{ fontSize: 12, marginRight: 20 }}>
              {data.stats?.partitionCount !== 0
                ? `${numeral(data.stats?.partitionCount || 0).format(
                    '0,0'
                  )} partition${data.stats?.partitionCount !== 1 ? 's' : ''}`
                : `Not Partitioned`}{' '}
              &bull;{' '}
              <strong>
                {numeral(data.stats?.totalCount || 0).format('0,0')} Events
              </strong>{' '}
              ({numeral(data.stats?.totalSize || 0).format('0,0b')})
            </span>
            <Button to={'/events/' + this.state.id + '/imports'} small>
              Import Data
            </Button>
            <Button
              onClick={this.onDelete}
              loading={this.state.isDeleting}
              small
              danger
            >
              Delete
            </Button>
          </div>
        </Subheader>
        <div className={css.wrapper}></div>
      </>
    )
  }
}

export default Event

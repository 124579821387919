import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import KeyForm from './key'

class AccountApiKey extends Component {
  render() {
    return (
      <div className="nestedNavContent">
        <div className="form">
          <h3>API Key</h3>
          <p>
            This api key can only be used to send events. There's no API access
            to chart data yet.
          </p>
          <KeyForm />
          Learn how to send events in our <Link to="/docs">documentation</Link>.
        </div>
      </div>
    )
  }
}

export default AccountApiKey

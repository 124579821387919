import React, { Component } from 'react'
import update from 'immutability-helper'

import Button from '../../shared/button'
import Org from '../../data/models/org'
import Loading from '../../shared/loading'
import toast from '../../data/toast'
import { TimeAgo } from '../../shared/timeago'
import alert from '../../data/alert'
import user from '../../data/user'

class AccountTeam extends Component {
  state = {
    id: this.props.match.params.id,
    isLoading: false,
    isLoadingMembers: false,
    isSaving: false,
    isInviting: false,
    inviteEmail: '',
    data: {},
    members: []
  }

  componentDidMount() {
    this.getData()
    this.getMembers()
  }

  getData = () => {
    this.setState({ isLoading: true })

    Org.Get(this.state.id, res => {
      this.setState({
        isLoading: false,
        data: res
      })
    })
  }

  getMembers = () => {
    this.setState({ isLoadingMembers: true })

    Org.GetMembers(this.state.id, res => {
      this.setState({
        isLoadingMembers: false,
        members: res
      })
    })
  }

  onChangeName = e => {
    const newData = update(this.state.data, {
      name: { $set: e.target.value }
    })

    this.setState({
      data: newData
    })
  }

  onSave = () => {
    this.setState({ isSaving: true })

    const id = this.state.id
    const data = {
      name: this.state.data.name
    }

    Org.Update(id, data, res => {
      this.setState({ isSaving: false })
      toast.open({ msg: 'Name updated', type: 'success' })
    })
  }

  onInvite = () => {
    this.setState({ isInviting: true })

    const { id, inviteEmail } = this.state

    Org.Invite(id, inviteEmail, res => {
      this.setState({ isInviting: false })
      this.getMembers()
    })
  }

  onRemove = id => {
    Org.RemoveUser(this.state.id, id, res => {
      if (res.error) {
        this.setState({ isLoadingMembers: false })
        alert.alert({
          title: 'Error',
          text: res.error
        })
      } else {
        toast.open({ msg: 'User removed', type: 'success' })
        this.getMembers()
      }
    })
  }

  onChangeAdmin = (id, isAdmin) => {
    const originalState = this.state.members
    const index = originalState.findIndex(m => m.userId === id)

    this.setState({
      members: update(originalState, {
        [index]: {
          isAdmin: { $set: isAdmin }
        }
      })
    })

    Org.UpdateAdmin(this.state.id, id, isAdmin, res => {
      if (res.error) {
        this.setState({
          isLoadingMembers: false,
          members: originalState
        })
        
        alert.alert({
          title: 'Error',
          text: res.error
        })
      } else {
        toast.open({ msg: 'Admin status updated', type: 'success' })
      }
    })
  }

  render() {
    const {
      isLoading,
      data,
      isSaving,
      isInviting,
      inviteEmail,
      members,
      isLoadingMembers
    } = this.state

    if (isLoading || isLoadingMembers) {
      return (
        <div className="nestedNavContent">
          <Loading />
        </div>
      )
    }

    const u = user.Get()
    const member = (members && members.find(m => m.userId === u.id)) || {}
    const isAdmin = member.isAdmin

    return (
      <div className="nestedNavContent">
        <div className="form">
          <h3>Manage {data.name}</h3>
          {isAdmin && (
            <div className="formRow">
              <label>Team Name</label>
              <input
                value={data.name}
                onChange={this.onChangeName}
                type="text"
                style={{ width: 400 }}
              />
              {isAdmin && (
                <Button loading={isSaving} onClick={this.onSave}>
                  Save
                </Button>
              )}
            </div>
          )}
          <div className="formRow">
            <label>Members</label>
            <ul className="list" style={{ marginTop: 0 }}>
              {members.map(m => (
                <li className="listItem">
                  <span className="listItem__title">{m.email}</span>
                  <div className="spacer" />
                  <span className="secondary">
                    joined {TimeAgo(m.createdAt)} ago
                  </span>
                  {isAdmin && (
                    <React.Fragment>
                      <div style={{ width: 80, marginLeft: 20 }}>
                        <label>
                          <input
                            type="checkbox"
                            checked={m.isAdmin}
                            onClick={() =>
                              this.onChangeAdmin(m.userId, !m.isAdmin)
                            }
                          />{' '}
                          Admin
                        </label>
                      </div>
                      <div style={{ width: 62 }}>
                        <Button small onClick={() => this.onRemove(m.userId)}>
                          Remove
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {isAdmin && (
            <div className="formRow">
              <label>Invite someone...</label>
              <input
                type="email"
                placeholder="email@example.com"
                style={{ width: 400 }}
                value={inviteEmail}
                onChange={e => this.setState({ inviteEmail: e.target.value })}
              />
              <Button loading={isInviting} onClick={this.onInvite}>
                Invite
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default AccountTeam

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import numeral from 'numeral'

import Overlay from '../../../shared/overlay'
import { TimeAgo } from '../../../shared/timeago'
import Button from '../../../shared/button'
import ellipsis from '../../../../images/ellipsis.svg'
import Highlight from '../../../shared/highlight'
import { isNewEvent } from '../../../data/models/events'

const Ellipsis = (
  <Button small ellipsis>
    <img src={ellipsis} alt="More" />
  </Button>
)

class Item extends Component {
  render() {
    const { item, onRemove, onFavorite, matches } = this.props
    const isNew = isNewEvent(item.createdAt)

    return (
      <div className="listItem">
        <Link to={'/query/' + item.id} className="listItem__title">
          <Highlight match={matches ? matches[0] : null} text={item.id} />
        </Link>
        {isNew && <div className="label label--new">NEW</div>}
        {item.stats?.totalCount && (
          <div className="label">
            <strong>{numeral(item.stats.totalCount).format('0,0')}</strong>{' '}
            Events
          </div>
        )}
        {item.stats?.totalSize && (
          <div className="label">
            {numeral(item.stats.totalSize).format('0,0 b')}
          </div>
        )}
        <div className="spacer" />
        <div className="listItem__actions">
          {TimeAgo(item.createdAt)} old
          <Overlay trigger={Ellipsis} position="bottom-left">
            <ul className="overlay__options">
              <li>
                {item.isFavorite ? (
                  <Button onClick={() => onFavorite(item.id, false)}>
                    Remove from Favorites
                  </Button>
                ) : (
                  <Button onClick={() => onFavorite(item.id, true)}>
                    Add to Favorites
                  </Button>
                )}
              </li>
              <li>
                <Link to={'/events/' + item.id + '/imports'}>Import Data</Link>
              </li>
              <li className="divider" />
              <li
                className="overlay__options--danger"
                onClick={() => onRemove(item.id, item.id)}
              >
                Delete
              </li>
            </ul>
          </Overlay>
        </div>
      </div>
    )
  }
}

export default Item

import React, { Component } from 'react'

class LogoIcon extends Component {
  render() {
    return (
      <svg
        width="60"
        height="50"
        viewBox="0 0 60 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m45.5 1 13.9 23a2 2 0 0 1 0 2l-14 23c-.3.6-1 1-1.6 1H16a2 2 0 0 1-1.8-1L.2 26a2 2 0 0 1 0-2l14-23c.4-.6 1-1 1.8-1h27.8a2 2 0 0 1 1.7 1ZM31 27 19.9 45.7h22.6l11.2-18.6H31Zm-4.3-11.4 4.3 7.2h22.6l-4.4-7.2H26.8ZM6 22.9h20L16 6.3 6 22.9Zm10 20.8 10-16.6H6l10 16.6Zm8.2-32.3h22.5l-4.2-7.1H19.9l4.3 7Z"
          fill="#1E1F31"
        />
      </svg>
    )
  }
}

export default LogoIcon

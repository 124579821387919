import React, { Component } from 'react'
import Loadable from 'react-loadable'
import Helmet from 'react-helmet'

import NavBar from './navbar'

import './styles.css'
import Loading from '../../shared/loading'
import Header from '../../layout/header'
import Subheader from '../../layout/header/subheader'
import Org from '../../data/models/org'

class Docs extends Component {
  state = {
    path: '',
    apiKey: 'YOUR_API_KEY'
  }

  componentDidMount() {
    this.setState({
      path: this.props.location.pathname.replace('/docs', '')
    })
    this.getApiKey()
  }

  getApiKey = () => {
    Org.GetAPIKey(res => {
      this.setState({
        apiKey: res.apiKey
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        path: this.props.location.pathname.replace('/docs', '')
      })
    }
  }

  render() {
    const LoadableComponent = Loadable({
      loader: () => import('./content' + this.state.path),
      loading: Loading
    })

    const pathParts = this.state.path
      .substring(1)
      .replace('-', ' ')
      .split('/')

    return (
      <React.Fragment>
        <Helmet>
          <title>Documentation</title>
        </Helmet>
        <Header pathname={this.props.location.pathname} />
        <Subheader />
        <div className="wr">
          <NavBar location={this.props.location} />
          <div className="docsWrapper">
            {pathParts.length > 1 && (
              <ul className="docsPath">
                {pathParts.map((part, i) => {
                  if (i !== 0) {
                    return <li key={i}> / {part}</li>
                  } else {
                    return <li key={i}>{part}</li>
                  }
                })}
              </ul>
            )}
            <LoadableComponent apiKey={this.state.apiKey} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Docs

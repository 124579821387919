import React from 'react'
import update from 'immutability-helper'
import classNames from 'classnames'

import Button from '../../../shared/button'

import css from './querying.module.css'
import Condition from './condition'
import Partitions from './partitions'
import EventPicker from './events'
import Timerange from './timerange'

const Conditions = ({
  id,
  setId,
  partitionList,
  hasMorePKs,
  fields,
  query,
  setQuery
}) => {
  const conditions = query.conditions || []

  const setConditions = conditions => {
    setQuery({ ...query, conditions })
  }

  const onAdd = () => {
    setConditions([...conditions, {}])
  }

  return (
    <div className={css.container}>
      <ul className={css.rows}>
        <li className={css.row}>
          <div className={css.item}>
            <label>Event</label>
            <EventPicker id={id} setId={setId} />
            <div style={{ width: 15 }} />
            <Timerange query={query} setQuery={setQuery} />
          </div>
        </li>
        {(hasMorePKs || partitionList?.length !== 0) && (
          <li className={css.row}>
            <div className={classNames(css.item, css.operator)}>WHERE</div>
            <div className={css.item}>
              <label>Partition is one of</label>
              <Partitions
                options={partitionList}
                keys={query.partitionKeys}
                setKeys={partitionKeys => setQuery({ ...query, partitionKeys })}
              />
            </div>
          </li>
        )}
        {conditions.map((item, index) => (
          <Condition
            index={index + (partitionList.length ? 1 : 0)}
            fields={fields}
            data={item}
            setCondition={item =>
              setConditions(update(conditions, { [index]: { $set: item } }))
            }
            onDelete={() =>
              setConditions(update(conditions, { $splice: [[index, 1]] }))
            }
          />
        ))}
      </ul>
      <Button onClick={onAdd} small>
        Add Condition
      </Button>
    </div>
  )
}

export default Conditions

const devRoot = 'http://localhost:8000'
const prodRoot = 'https://api.evenq.io'
const stagingRoot = 'https://api-staging.evenq.io'

const stripe_pk_test = ''
const stripe_pk_live = ''

const isStaging = window.location.hostname === 'app-staging.evenq.io'
const isDev = window.location.hostname === 'localhost'

const config = {
  API_ROOT: isDev ? devRoot : isStaging ? stagingRoot : prodRoot,
  STRIPE_KEY: isDev ? stripe_pk_test : stripe_pk_live,
  PUSHER_APP_KEY: '',
  PUSHER_CLUSTER: 'mt1'
}

export default config

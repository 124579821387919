import React from 'react'
import setMilliseconds from 'date-fns/setMilliseconds'
import setSeconds from 'date-fns/setSeconds'
import subMinutes from 'date-fns/subMinutes'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

const options = [
  { label: '5 min', value: 5 },
  { label: '30 min', value: 30 },
  { label: '60 min', value: 60 },
  { label: '6 hours', value: 360 },
  { label: '12 hours', value: 720 },
  { label: '24 hours', value: 1440 },
  { label: '7 days', value: 10080 },
  { label: '30 days', value: 43200 }
]

export const getRelativeTimes = minBeforeNow => {
  const now = utcToZonedTime(new Date(), 'UTC')
  const to = setMilliseconds(setSeconds(now, 0), 0)
  const from = subMinutes(to, Number(minBeforeNow))

  return {
    to: to,
    from: from
  }
}

const Timesets = ({ onUpdate }) => {
  const onChange = e => {
    // Set 45 seconds to 1 September 2014 11:30:40:
    const { from, to } = getRelativeTimes(e.target.value)
    onUpdate(from, to)
  }

  return (
    <select value="" onChange={onChange} placeholder="Presets">
      <option value="" disabled>
        Presets
      </option>
      {options.map(opt => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </select>
  )
}

export default Timesets

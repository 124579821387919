import React, { Component } from 'react'
import numeral from 'numeral'
import emitter from 'tiny-emitter/instance'
import { Link } from 'react-router-dom'

import { TimeAgo } from '../timeago'

import css from './usage.module.css'
import user from '../../data/user'

class Usage extends Component {
  state = {
    usage: user.GetUsage()
  }

  componentDidMount() {
    emitter.on('usage.updated', this.onUpdate)
  }

  componentWillUnmount() {
    emitter.off('usage.updated', this.onUpdate)
  }

  onUpdate = usage => {
    this.setState({ usage })
  }

  render() {
    const usage = this.state.usage

    if (!usage) {
      return null
    }

    const { totals, quotas, periodEnd } = usage

    if (!totals || !quotas || !periodEnd) {
      return null
    }

    const eventLimit = quotas.freeEventLimit + quotas.planEventLimit
    const queryLimit = quotas.freeQueryLimit + quotas.planQueryLimit

    const percentageEvents = (totals.events / eventLimit) * 100
    const percentageQueries = (totals.queries / queryLimit) * 100

    let queriesColor = 'var(--color-action)'
    let eventsColor = 'var(--color-action)'

    let warnColor = '#ff8c24'
    let dangerColor = '#ff5151'

    if (percentageEvents > 100) {
      eventsColor = dangerColor
    } else if (percentageEvents > 80) {
      eventsColor = warnColor
    }

    if (percentageQueries > 100) {
      queriesColor = dangerColor
    } else if (percentageQueries > 80) {
      queriesColor = warnColor
    }

    return (
      <div className={css.usage}>
        <h4>Usage</h4>
        <ul>
          <li className={css.values}>
            <span>Events</span>
            <span>
              {numeral(totals.events).format()} / {numeral(eventLimit).format()}
            </span>
          </li>
          <li>
            <div className={css.bar}>
              <div
                className={css.barValue}
                style={{
                  width: percentageEvents.toFixed(0) + '%',
                  backgroundColor: eventsColor
                }}
              />
            </div>
          </li>
          <li className={css.values}>
            <span>Queries</span>
            <span>
              {numeral(totals.queries).format()} /{' '}
              {numeral(queryLimit).format()}
            </span>
          </li>
          <li>
            <div className={css.bar}>
              <div
                className={css.barValue}
                style={{
                  width: percentageQueries.toFixed(0) + '%',
                  backgroundColor: queriesColor
                }}
              />
            </div>
          </li>
          <li>
            Next period{' '}
            <Link to="/settings/usage">in {TimeAgo(periodEnd)}</Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default Usage

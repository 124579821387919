import React, { useState } from 'react'

import css from './partitions.module.css'

const Partitions = ({ options, keys = [], setKeys }) => {
  const [val, setVal] = useState('')

  const onSubmit = e => {
    e.preventDefault()
    if (val.length) {
      setKeys([...new Set([...keys, val])])
      setVal('')
    }
  }

  const onRemove = key => {
    setKeys(keys.filter(k => k !== key))
  }

  return (
    <div className={css.container}>
      <ul className={css.list}>
        {keys.map(k => (
          <li key={k} onClick={() => onRemove(k)}>
            {k} <button>&times;</button>
          </li>
        ))}
      </ul>
      <form onSubmit={onSubmit} className={css.form}>
        <input
          type="text"
          list="pks"
          value={val}
          onChange={e => setVal(e.target.value)}
          placeholder="Your PK"
        />
        <datalist id="pks">
          {options
            ?.filter(opt => !keys.includes(opt))
            .map(opt => (
              <option key={opt} value={opt} />
            ))}
        </datalist>
        <button>Add</button>
      </form>
    </div>
  )
}

export default Partitions

import React, { useState } from 'react'
import Line from '../../../components/charts/Line'

const Chart = ({ lineIndex, setLineIndex, data }) => {
  const [hasFocus, setHasFocus] = useState(false)

  const datasets = [
    {
      isOriginal: true,
      data: data,
      id: 0,
      settings: {
        color: '#7e7e8f'
      }
    }
  ]

  return (
    <Line
      datasets={datasets}
      index={lineIndex}
      indexChange={setLineIndex}
      showFocus={hasFocus}
      onShowFocus={setHasFocus}
      showArea={false}
    />
  )
}

export default Chart

import React, { Component } from 'react'

import Org from '../../data/models/org'
import Button from '../../shared/button'

class KeyForm extends Component {
  state = {
    apiKey: 'Loading...',
    isVisible: 'true',
    isLoading: false
  }

  componentDidMount() {
    this.getKey()
  }

  getKey = () => {
    Org.GetAPIKey(res => {
      this.setState({
        isLoading: false,
        apiKey: res.apiKey,
        isVisible: false
      })
    })
  }

  toggleShow = () => {
    this.setState({
      isVisible: !this.state.isVisible
    })
  }

  render() {
    const isVisible = this.state.isVisible

    return (
      <div className="formRow">
        <input
          type={isVisible ? 'text' : 'password'}
          value={this.state.apiKey}
          style={{ width: 400 }}
        />
        <Button onClick={this.toggleShow}>{isVisible ? 'Hide' : 'Show'}</Button>
      </div>
    )
  }
}

export default KeyForm

import React, { Component } from 'react'
import Helmet from 'react-helmet'

import eventData from '../../../../data/models/events'
import Loading from '../../../../shared/loading'
import Subheader from '../../../../layout/header/subheader'
import API from '../../../../data/api'
import Button from '../../../../shared/button'

import Mapping from './mapping'
import Upload from './upload'

class Import extends Component {
  state = {
    id: this.props.match.params.id,
    data: {},
    import: {},
    history: [],
    mapping: {},
    isLoading: true,
    isLoadingHistory: true,
    isUploading: false,
    isImporting: false,
    progress: 0,
    error: false
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    eventData.Get(this.state.id, res => {
      this.setState({
        data: res,
        isLoading: false
      })
    })
  }

  onFileChange = e => {
    if (e.target.files.length !== 1) {
      return
    }

    const file = e.target.files[0]

    if (file.size > 1000 * 1000 * 1000) {
      this.setState({ error: 'file size too large' })
      return
    }

    // Instantiate a new FileReader
    var reader = new FileReader()

    // Read our file to an ArrayBuffer
    reader.readAsArrayBuffer(file)

    // Handler for onloadend event.  Triggered each time the reading operation is completed (success or failure)
    reader.onloadend = evt => {
      // Get the Array Buffer
      var data = evt.target.result

      // Grab our byte length
      var byteLength = data.byteLength

      // Convert to conventional array, so we can iterate though it
      var ui8a = new Uint8Array(data, 0)

      // Used to store each character that makes up CSV header
      var headerString = ''

      // Iterate through each character in our Array
      for (var i = 0; i < byteLength; i++) {
        // Get the character for the current iteration
        var char = String.fromCharCode(ui8a[i])

        // Check if the char is a new line
        if (char.match(/[^\r\n]+/g) !== null) {
          // Not a new line so lets append it to our header string and keep processing
          headerString += char
        } else {
          // We found a new line character, stop processing
          break
        }
      }

      // Split our header string into an array
      this.generateMapping(headerString.split(','))
    }

    this.create(file.name, file.size, res => {
      if (res.id) {
        this.beginUpload(res.uploadUrl, file)
      }
    })
  }

  generateMapping = headers => {
    // remove quotes and turn into object
    let mapping = Object.fromEntries(
      headers.map(h => [h.replace(/^"(.*)"$/, '$1'), null])
    )

    // if we have any fields that are identical to the csv fields,
    // we assign them automatically
    Object.keys(mapping).forEach(key => {
      mapping[key] = key
    })

    this.setState({
      mapping
    })
  }

  create = (name, size, cb) => {
    const data = {
      fileName: name,
      fileSize: size
    }

    eventData.CreateImport(this.state.id, data, res => {
      this.setState({ import: res })
      cb && cb(res)
    })
  }

  beginUpload = (url, data) => {
    this.setState({ isUploading: true })
    API.PostProgress({
      url: url,
      data: data,
      success: data => {
        this.setState({ isUploading: false })
      },
      error: data => console.log(data),
      onProgress: progress => this.setState({ progress })
    })
  }

  setMapping = mm => {
    this.setState({ mapping: mm })
  }

  startImport = () => {
    if (!this.state.import.id) {
      return
    }

    this.setState({ isImporting: true })

    const data = {
      mappings: this.state.mapping
    }

    eventData.StartImport(this.state.id, this.state.import.id, data, res => {
      this.props.history.push('/events/' + this.state.id + '/imports')
    })
  }

  render() {
    const {
      data,
      progress,
      isUploading,
      isImporting,
      mapping,
      isLoading,
      error
    } = this.state

    if (isLoading) {
      return <Loading />
    }

    return (
      <>
        <Helmet>
          <title>{data.id} Import</title>
        </Helmet>
        <Subheader
          parent={{
            title: data.id,
            path: '/events/' + data.id + '/imports'
          }}
          title="Data Import"
        />
        <div className="wr">
          <div className="notice">
            <ul>
              <li>Your csv file must have a header column</li>
              <li>Maximum file size is 1GB (if your browser supports it)</li>
              <li>
                If you don't assign a column with a valid date/time to
                "Timestamp", your events will be timestamped with the import
                time.
              </li>
              <li>
                Timestamps must be in one of the following formats:
                <ul>
                  <li>epoch seconds</li>
                  <li>2006-01-02 15:04:05.999</li>
                  <li>2006-01-02 15:04:05</li>
                  <li>2006-01-02T15:04:05Z07:00</li>
                </ul>
              </li>
            </ul>
          </div>
          <Upload
            onFileChange={this.onFileChange}
            progress={progress}
            error={error}
          />
          {progress !== 0 && (
            <Mapping mapping={mapping} setMapping={this.setMapping} />
          )}
          <Button
            large
            primary
            onClick={this.startImport}
            disabled={isImporting || isUploading || progress === 0}
            loading={isImporting || isUploading}
            style={{ marginTop: 20 }}
          >
            {isUploading
              ? 'Uploading...'
              : isImporting
              ? 'Importing'
              : 'Start Import'}
          </Button>
        </div>
      </>
    )
  }
}

export default Import

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './footer.css'

const groups = [
  {
    title: 'Docs',
    links: [
      {
        title: 'Authentication',
        path: '/docs/getting-started/authentication'
      },
      {
        title: 'Naming Conventions',
        path: '/docs/learn/naming-conventions'
      },
      {
        title: 'Partition Keys',
        path: '/docs/learn/partition-keys'
      },
      {
        title: 'Use cases',
        path: '/docs/learn/use-cases'
      },
      {
        title: 'Pricing',
        path: '/docs/learn/pricing'
      }
    ]
  },
  {
    title: 'Integrations',
    links: [
      {
        title: 'URL Params',
        path: '/docs/send-events/url-params'
      },
      {
        title: 'JSON Post',
        path: '/docs/send-events/url-params'
      },
      {
        title: 'Node.js',
        path: '/docs/send-events/nodejs'
      },
      {
        title: 'Go',
        path: '/docs/send-events/golang'
      }
    ]
  },
  {
    title: 'Profiles',
    links: [
      {
        title: 'GitHub',
        path: 'https://github.com/evenq/'
      },
      {
        title: 'Twitter',
        path: 'https://twitter.com/getevenq'
      }
    ]
  },
  {
    title: 'Legal',
    links: [
      {
        title: 'Privacy Policy',
        path: 'https://evenq.io/privacy-policy'
      },
      {
        title: 'Terms of Service',
        path: 'https://evenq.io/terms'
      },
      {
        title: 'Cookie Policy',
        path: 'https://evenq.io/cookie-policy'
      }
    ]
  }
]

class Footer extends Component {
  render() {
    if (this.props.location.pathname.indexOf('/dashboards/') === 0) {
      return null
    }

    return (
      <div className="footerWrapper">
        <footer className="footer">
          <div className="wr">
            <ul className="footerLinks">
              <li>
                <span>© Evenq 2022</span>
                <ul>
                  <a
                    href="https://status.evenq.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Status
                  </a>
                </ul>
              </li>
            </ul>
            <ul className="footerLinks">
              {groups.map((group, i) => {
                return (
                  <li key={i}>
                    <span>{group.title}</span>
                    <ul>
                      {group.links.map((link, j) => (
                        <li key={j}>
                          {link.path.indexOf('https://') === 0 ? (
                            <a href={link.path}>{link.title}</a>
                          ) : (
                            <Link to={link.path}>{link.title}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer

import React, { Component } from 'react'

import './loading.css'

class Loading extends Component {
  render() {
    let classes = 'loading'

    if (this.props.page) {
      classes += ' loading--page'
    }

    if (this.props.noFlex) {
      classes += ' loading--noflex'
    }

    if (this.props.small) {
      classes += ' loading--small'
    }

    if (this.props.error) {
      return <div>error</div>
    }

    return (
      <div className={classes}>
        <div className="loader">Loading...</div>
        {this.props.text && (
          <span className="loadingText">{this.props.text}</span>
        )}
      </div>
    )
  }
}

export default Loading

import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import Event from './event'
import Settings from './event/settings'
import Create from './event/create'
import Import from './event/import/import'
import Imports from './event/import/imports'

class EventsHost extends Component {
  render() {
    return (
      <Switch>
        <Route path="/events/new" component={Create} />
        <Route path="/events/:id/imports/new" component={Import} />
        <Route path="/events/:id/imports" component={Imports} />
        <Route path="/events/:id/settings" component={Settings} />
        <Route path="/events/:id" component={Event} />
      </Switch>
    )
  }
}

export default EventsHost

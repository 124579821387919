import React, { Component } from 'react'
import Button from '../../shared/button'
import user from '../../data/user'
import toast from '../../data/toast'
import { ValidateEmail } from '../../data/validators'

class Account extends Component {
  state = {
    name: '',
    email: '',
    isSaving: false,
    isLoggingOut: false,
    error: false
  }

  componentDidMount() {
    const u = user.Get()
    this.setState({
      name: u.name,
      email: u.email
    })
  }

  onUpdateProfile = e => {
    e.preventDefault()

    const data = {
      name: this.state.name,
      email: this.state.email
    }

    if (!this.validateData(data)) {
      return
    }

    this.setState({
      isSaving: true,
      error: false
    })

    user.UpdateMeta(data, res => {
      this.setState({ isSaving: false })
      if (res.error) {
        this.setState({ error: res.error })
      } else {
        toast.open({ msg: 'Profile Updated!', type: 'success' })
      }
    })
  }

  validateData = data => {
    if (!ValidateEmail(data.email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    return true
  }

  onLogout = () => {
    this.setState({ isLoggingOut: true })
    user.Logout(() => {
      // hard reload
      window.location.href = '/login'
    })
  }

  render() {
    return (
      <div className="nestedNavContent" style={{ maxWidth: 620 }}>
        <div className="form">
          <h3>My Profile</h3>
          <div class="formRow" style={{ marginTop: 20 }}>
            <label>Name</label>
            <input
              type="text"
              value={this.state.name}
              onChange={e => this.setState({ name: e.target.value })}
              placeholder="Your Name"
            />
          </div>
          <div class="formRow">
            <label>Email</label>
            <input
              type="email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />
          </div>
          {this.state.error && (
            <div className="formError">{this.state.error}</div>
          )}
          <div className="formActions">
            <Button
              primary={true}
              loading={this.state.isSaving}
              onClick={this.onUpdateProfile}
            >
              Update Profile
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Account

import React, { Component } from 'react'

import eventData from '../../../data/models/events'
import Select from '../../../shared/select/select'

import css from './querying.module.css'

class EventPicker extends Component {
  state = {
    isLoading: false,
    events: []
  }

  componentDidMount() {
    this.getEvents()
  }

  getEvents = () => {
    this.setState({ isLoading: true })

    eventData.GetList(res => {
      this.setState({
        isLoading: false,
        events: res
      })
    })
  }

  onChange = val => {
    this.props.setId(val)
  }

  render() {
    return (
      <div className={css.select}>
        <Select
          options={this.state.events.map(e => ({ value: e.id, label: e.id }))}
          value={this.props.id}
          onChange={this.onChange}
          noBorder
        />
      </div>
    )
  }
}

export default EventPicker

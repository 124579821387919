import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import User from '../../data/user'
import { ValidateEmail } from '../../data/validators'

import './auth.css'

import Button from '../../shared/button'
import AuthHeader from './header'

class Login extends Component {
  state = {
    email: '',
    pw: '',
    loading: false,
    error: ''
  }

  componentDidMount() {
    User.CheckAuth(res => {
      if (res.id) {
        this.props.history.push('/')
      }
    })
  }

  onSubmit = e => {
    e.preventDefault()

    if (!this.validateData(this.state.email, this.state.pw)) {
      return
    }

    this.setState({ loading: true })

    User.Login(this.state.email, this.state.pw, res => {
      if (res.success) {
        this.props.history.push('/')
      } else {
        this.setState({ loading: false, error: res.error })
      }
    })
  }

  validateData(email, pw) {
    if (!ValidateEmail(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }
    if (pw.length === 0) {
      this.setState({ error: 'Please enter your password' })
      return false
    }
    return true
  }

  render() {
    return (
      <div className="authWrapper">
        <AuthHeader />
        <h1 className="authTitle">Login</h1>
        <div className="authBox">
          <form onSubmit={this.onSubmit} className="authForm">
            <div className="authFormSection">
              <label>Email</label>
              <input
                type="email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                placeholder="email@example.com"
                autoFocus
              />
            </div>
            <div className="authFormSection">
              <label>Password</label>
              <input
                type="password"
                value={this.state.pw}
                onChange={e => this.setState({ pw: e.target.value })}
              />
            </div>
            <div className="authFormError">{this.state.error}</div>
            <div className="authFormActions">
              <Link to="/forgot">I forgot my password</Link>
              <Button primary large loading={this.state.loading}>
                Login
              </Button>
            </div>
          </form>
        </div>
        <Button to="/signup">
          I don't have an account yet
        </Button>
      </div>
    )
  }
}

export default Login

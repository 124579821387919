import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import toast from '../data/toast'

import './toast.css'

class ToastHost extends Component {
  state = {
    isVisible: false,
    toast: {
      msg: ''
    }
  }

  componentDidMount() {
    toast.bindEvents(this.onToast)
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  onToast = toast => {
    this.timeout && clearTimeout(this.timeout)
    this.setState({
      isVisible: true,
      toast: toast
    })
    this.timeout = setTimeout(this.hide, toast.timeout || 3500)
  }

  hide = () => {
    // we dont clear the data so it can hide gracefully
    this.setState({ isVisible: false })
  }

  render() {
    const toast = this.state.toast

    let classes = 'toast'
    if (toast.type) {
      classes += ' toast--' + toast.type
    }
    if (this.state.isVisible) {
      classes += ' visible'
    }

    return (
      <div className={classes}>
        <span>{toast.msg}</span>
        {toast.link && (
          <Link to={toast.link.url} onClick={this.hide}>
            {toast.link.title}
          </Link>
        )}
      </div>
    )
  }
}

export default ToastHost

import React, { Component } from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer/dist'

import './highlighter.css'

class Highlighter extends Component {
  render() {
    return (
      <Highlight
        {...defaultProps}
        code={this.props.code}
        language={this.props.lang}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    )
  }
}

export default Highlighter

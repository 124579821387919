import React, { Component } from 'react'
import numeral from 'numeral'
import emitter from 'tiny-emitter/instance'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import Loading from '../../shared/loading'
import Org from '../../data/models/org'
import user from '../../data/user'

import css from './usage.module.css'

class UsageHistory extends Component {
  state = {
    data: [],
    usage: user.GetUsage(),
    isLoading: true
  }

  componentDidMount() {
    this.getData()
    emitter.on('usage.updated', this.onUpdate)
  }

  componentWillUnmount() {
    emitter.off('usage.updated', this.onUpdate)
  }

  onUpdate = usage => {
    this.setState({ usage })
  }

  getData = () => {
    Org.Usage(res => {
      this.setState({
        data: res,
        isLoading: false
      })
    })
  }

  render() {
    const { isLoading, data } = this.state

    const historical = data?.historical || []
    const totals = data?.totals || {}
    const quotas = data?.quotas || {}

    const eventLimit = quotas.freeEventLimit + quotas.planEventLimit
    const queryLimit = quotas.freeQueryLimit + quotas.planQueryLimit

    return (
      <div className="nestedNavContent">
        <div className="form">
          <h3>Quota Usage</h3>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <p>
                The current billing period runs from{' '}
                <strong>{data.periodStart.substr(0, 10)}</strong> to{' '}
                <strong>{data.periodEnd.substr(0, 10)}</strong>. <br />
                <br />
                So far, you have sent {numeral(totals.events).format(
                  '0,0'
                )} / {numeral(eventLimit).format('0,0')} events and made{' '}
                {numeral(totals.queries).format('0,0')} /{' '}
                {numeral(queryLimit).format('0,0')} queries. Queries made from
                within this app don't count against your quota.{' '}
                {quotas.freeLimitsExpire && (
                  <span style={{ marginBottom: 20, fontWeight: 500 }}>
                    You have {numeral(quotas.freeEventLimit).format('0,0')} free
                    events and {numeral(quotas.freeQueryLimit).format('0,0')}{' '}
                    free queries until{' '}
                    {format(parseISO(quotas.freeLimitsExpire), 'yyyy-MM-dd')} as
                    part of our free 30-day signup bonus.
                  </span>
                )}
              </p>
              <table className={css.usageTable}>
                <thead>
                  <tr>
                    <th>Period</th>
                    <th>Events</th>
                    <th>Queries</th>
                  </tr>
                </thead>
                <tbody>
                  {historical.map((row, i) => (
                    <tr key={i}>
                      <td>{row.period}</td>
                      <td>{numeral(row.events).format('0,0')}</td>
                      <td>{numeral(row.queries).format('0,0')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default UsageHistory

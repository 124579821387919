import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './nested.css'

class NestedNav extends Component {
  render() {
    const { title, base, data, active } = this.props

    let pathBase = ''
    if (base) {
      pathBase = '/' + base
    }

    return (
      <div className="nestedNav">
        <h4>{title}</h4>
        <ul>
          {data.map(group => {
            let groupPath = ''
            if (group.path) {
              groupPath = '/' + group.path
            }

            return (
              <li key={group.path}>
                <span>{group.title}</span>
                <ul>
                  {group.items.map(item => {
                    let itemPath = ''
                    if (item.path) {
                      itemPath = '/' + item.path
                    }

                    const path = pathBase + groupPath + itemPath
                    const className = path === active ? 'active' : ''

                    return (
                      <li key={item.path} className={className}>
                        <Link to={path}>{item.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default NestedNav

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Subheader extends Component {
  state = {
    isSticky: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    const isSticky = window.pageYOffset > 50

    if (isSticky !== this.state.isSticky) {
      this.setState({ isSticky })
    }
  }

  render() {
    const { parent, title, children, filter, actions, onFilter } = this.props

    let className = 'subheader'
    if (this.state.isSticky) {
      className += ' sticky'
    }

    let wrapperClass = 'wr'
    if (this.props.fullWidth) {
      wrapperClass += ' wr--fullWidth'
    }

    let isEmpty = !parent && !title && !children
    let hasFilter = !!onFilter

    return (
      <div className={className}>
        {!isEmpty && (
          <div className={wrapperClass}>
            {(parent || title) && (
              <div className="subheader__meta">
                {parent && (
                  <Link to={parent.path} className="parent">
                    {parent.title}
                  </Link>
                )}
                {title && <h2>{title}</h2>}
              </div>
            )}
            {children}
            {hasFilter && (
              <div className="actions">
                <input
                  className="valueInput"
                  type="text"
                  value={filter}
                  placeholder="Type to filter..."
                  onChange={e => onFilter(e.target.value)}
                />
                {actions}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Subheader

import React, { Component } from 'react'

import User from '../../data/user'
import { ValidateEmail } from '../../data/validators'

import './auth.css'
import Button from '../../shared/button'
import AuthHeader from './header'
import ValueProps from './valueprops'

class Signup extends Component {
  state = {
    // form data
    email: '',
    pw: '',
    acceptTerms: false,
    acceptEmail: false,
    // UI state
    loading: false,
    error: ''
  }

  componentDidMount() {
    User.CheckAuth(res => {
      if (res.id) {
        this.props.history.push('/')
      }
    })
  }

  onSubmit = e => {
    e.preventDefault()

    // we temporarily disable signups
    return

    const data = {
      email: this.state.email,
      password: this.state.pw,
      emailOptIn: this.state.acceptEmail
    }

    if (!this.validateData(data)) {
      return
    }

    this.setState({ loading: true })

    User.Signup(data, res => {
      this.setState({ loading: false })
      if (res.id) {
        // hard reload to avoid some issues
        window.location = '/'
      } else {
        this.setState({ error: res.error })
      }
    })
  }

  validateData(data) {
    if (!ValidateEmail(data.email)) {
      this.setState({ error: 'Please enter a valid email address.' })
      return false
    }
    if (data.password.length < 6) {
      this.setState({
        error: 'Your password has to be at least 6 characters long.'
      })
      return false
    }

    if (!this.state.acceptTerms) {
      this.setState({
        error:
          'You need to agree with our Privacy Policy and Terms of Service to sign up.'
      })
      return false
    }

    return true
  }

  render() {
    return (
      <div className="authWrapper">
        <AuthHeader />
        <h1 className="authTitle">Create your Account</h1>
        <div className="authBox">
          <form onSubmit={this.onSubmit} className="authForm">
            <div className="authFormSection">
              <label>Email</label>
              <input
                type="email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
              />
            </div>
            <div className="authFormSection">
              <label>Password</label>
              <input
                type="password"
                value={this.state.pw}
                onChange={e => this.setState({ pw: e.target.value })}
              />
            </div>
            <div
              className="authFormNotice"
              onClick={() =>
                this.setState({ acceptTerms: !this.state.acceptTerms })
              }
            >
              <span
                aria-checked={this.state.acceptTerms}
                aria-labelledby="label-terms"
                id="terms"
                role="checkbox"
                tabIndex="0"
                aria-label=""
                placeholder=""
                className={'ckbox' + (this.state.acceptTerms ? ' checked' : '')}
              />
              <label htmlFor="terms" id="label-terms">
                I agree to the Evenq
                <br />
                <a
                  href="https://evenq.io/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                &amp;{' '}
                <a
                  href="https://evenq.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </label>
            </div>
            <div
              className="authFormNotice"
              onClick={() =>
                this.setState({ acceptEmail: !this.state.acceptEmail })
              }
            >
              <span
                aria-checked={this.state.acceptEmail}
                aria-labelledby="label-terms"
                id="terms"
                role="checkbox"
                tabIndex="0"
                aria-label=""
                placeholder=""
                className={'ckbox' + (this.state.acceptEmail ? ' checked' : '')}
              />
              <label htmlFor="terms" id="label-terms" className="">
                I'd like to receive occasional product updates via email (you
                can unsubscribe at any time).
              </label>
            </div>
            <div className="authFormError">{this.state.error}</div>
            <Button
              primary
              large
              loading={this.state.loading}
              style={{ margin: '0 auto', marginTop: 25, display: 'flex' }}
              disabled
            >
              Create my account
            </Button>
            <div className="notice" style={{ marginTop: 20 }}>
              We are currently adding users on an invite-only basis. To register
              your interest, please visit{' '}
              <a href="https://evenq.io/signup">evenq.io/signup</a>
            </div>
          </form>
          <ValueProps />
        </div>
        <Button to="/login">I already have an account</Button>
      </div>
    )
  }
}

export default Signup

import React, { Component } from 'react'
import queryString from 'query-string'

import User from '../../data/user'
import './auth.css'

import toast from '../../data/toast'
import Button from '../../shared/button'
import AuthHeader from './header'

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    const query = queryString.parse(this.props.location.search)

    this.state = {
      // data
      password: '',
      passwordConfirm: '',
      token: query.t,
      // UI state
      error: '',
      loading: false,
      success: false
    }
  }

  onSubmit = e => {
    e.preventDefault()

    const data = {
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm,
      token: this.state.token
    }

    if (!this.validateData(data)) {
      return
    }

    this.setState({ loading: true, error: false })

    User.ResetPassword(data, res => {
      this.setState({ loading: false })
      if (res.success) {
        this.setState({ success: true })
        toast.open({ msg: 'Password reset successful!', type: 'success' })
      } else {
        this.setState({ error: res.error })
      }
    })
  }

  validateData(data) {
    if (data.password.length < 6) {
      this.setState({
        error: 'Your new password has to be at least 6 characters long.'
      })
      return false
    }
    if (data.password !== data.passwordConfirm) {
      this.setState({ error: "Your passwords don't match." })
      return false
    }
    if (!data.token) {
      this.setState({
        error:
          'This URL is missing the token parameter. Please copy the link from the email we sent you.'
      })
      return false
    }

    return true
  }

  render() {
    return (
      <div className="authWrapper">
        <AuthHeader />
        <h1 className="authTitle">Create New Password</h1>
        <div className="authBox">
          <form onSubmit={this.onSubmit} className="authForm">
            <div className="authFormSection">
              <label>New Password</label>
              <input
                type="password"
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
                autoFocus
              />
            </div>
            <div className="authFormSection">
              <label>Repeat New Password</label>
              <input
                type="password"
                value={this.state.passwordConfirm}
                onChange={e =>
                  this.setState({ passwordConfirm: e.target.value })
                }
              />
            </div>
            <div className="authFormError">{this.state.error}</div>
            {this.state.success ? (
              <Button to="/" primary>
                View Dashboard
              </Button>
            ) : (
              <Button primary right loading={this.state.loading}>
                Update Password
              </Button>
            )}
          </form>
        </div>
      </div>
    )
  }
}

export default ResetPassword

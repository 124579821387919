import React from 'react'
import Highlighter from '../../../../shared/highlighter'

const Code = ({ name, fields }) => {
  let code = `qm.Event("${name}", qm.Fields{
  ${fields.map(f => `"${f.key}": ${f.key}`).join(`,\n  `)},
})`

  return (
    <div>
      <Highlighter code={code} lang="go" />
    </div>
  )
}

export default Code

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './notfound.css'
import Subheader from './header/subheader'

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <Subheader />
        <div className="notFound">
          <h1>Page not found</h1>
          <p>This page does not exist or you don't have access to it.</p>
          <Link to="/" className="btn">
            Back to Dashboard
          </Link>
        </div>
      </React.Fragment>
    )
  }
}

export default NotFound

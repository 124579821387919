import React, { Component } from 'react'
import parse from 'autosuggest-highlight/parse'

class Highlight extends Component {
  render() {
    const { match, text } = this.props

    if (!match) {
      return <span>{text}</span>
    }

    // the fuse api and parse have a slightly different system
    // so we increase the end index by one to make it match
    const sections = match.indices.map(ind => [ind[0], ind[1] + 1])

    const parts = parse(text, sections)

    return (
      <span>
        {parts.map((part, i) => {
          if (part.highlight === true) {
            return <mark key={i}>{part.text}</mark>
          }
          return part.text
        })}
      </span>
    )
  }
}

export default Highlight
